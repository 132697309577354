import { useState } from "react";
import Button from "../../UI/Buttons/Button";
import "./Variant.scss"
import ReactHtmlParser from 'html-react-parser';
const Variant = (props) => {
    return(
        <div className={`card ${props?.highlighted ? "highlighted-card" : ''}`}>
            {props?.badge &&
                <div className="badge">
                    {props?.badge}
                </div>
            }
            <div className="item-container">
                <div className="item-wrapper">
                    <img src="https://via.placeholder.com/640x480.png" />
                    <h2 className={props?.emphasis && "price-emphasis"}>{props.title}</h2> 
                    {props?.titleDescription && <div className="offer-descriprion">{ReactHtmlParser(props.titleDescription)}</div>}
                </div>
         
            <div className="item-wrapper">
                    <div className="price-wrapper">
                        <span className="previous-price">{props.previousPrice}</span>
                        <span className={`price ${props?.emphasis && "price-emphasis"}`} > <strong> {props.price}</strong> </span>
                        <span className="tax-text">{props.taxText}</span>
                        <span className="price-term"> {props.priceTerm} </span>
                    </div>
                    <div className="price-description">{ReactHtmlParser(props.priceDescription)}</div>
                </div>
            
                <div className="item-wrapper">
                    <Button> {props.buttonText} </Button>
                </div> 
            </div>
            <div className="item-wrapper">
                <div className="offer-description">
                    {ReactHtmlParser(props.offerDescription)}
                </div>

            </div>
        </div>
    )

}

export default Variant;