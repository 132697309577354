import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n'

export function PaymentTypeLogo(props) {
    const { t, i18n } = useTranslation();
    let paymentType = props.paymentType;
    const [content, setContent] = useState([]);
    const [animatedContent, setAnimatedContent] = useState([]);
    const [visibleIconIndex, setVisibleIconIndex] = useState(-1);


    const toggleVisibleIndex = () => {
        setInterval(() => {
            setVisibleIconIndex((prev) => {
                return prev < animatedContent.length - 1 ? prev + 1 : 0
            })
        }, 2000)
    }

    useEffect(() => {
        if (paymentType.subpaymenttypes) {
            displayLogos();
        }
    }, [])

    useEffect(() => {
        if (animatedContent.length > 0) {
            //only when animatedContent has elements
            toggleVisibleIndex();
        }

    }, [animatedContent])




    const displayLogos = () => {
        if (paymentType.subpaymenttypes.length > 0) {
            //when no of cards < 4 and no animation is needed
            if (paymentType.subpaymenttypes.length <= 4) {
                let logoArrays = [];
                paymentType.subpaymenttypes.map((subpaymenttype) => {
                    const tempArray = subpaymenttype.paymenttypename.split(" ");
                    let cardName = tempArray[0];
                    if (!logoArrays.includes(cardName)) {
                        logoArrays.push(cardName)
                        content.push(<div className={`payment-icon ${subpaymenttype.logoname}`}><img src={'assets/paymenttypes/logos/' + subpaymenttype.logoname + '.svg'} alt={t(subpaymenttype.paymenttypenamelanguagekey)} /></div>);
                    }
                })
            }
            //in case the no is > 4, first 3 will be rendered as above and rest will be animated
            else if (paymentType.subpaymenttypes.length > 4) {
                let logoArrays = [];
                paymentType.subpaymenttypes.map((subpaymenttype, index) => {
                    const tempArray = subpaymenttype.paymenttypename.split(" ");
                    let cardName = tempArray[0]; //array for storing card names

                    if (!logoArrays.includes(cardName)) {

                        //to avoid duplication as we are pushing html elements
                        logoArrays.push(cardName)

                        if (index < 3) {
                            content.push(<div className={`payment-icon ${subpaymenttype.logoname}`}><img src={'assets/paymenttypes/logos/' + subpaymenttype.logoname + '.svg'} alt={t(subpaymenttype.paymenttypenamelanguagekey)} /></div>);
                        }
                        else if (index >= 3) {
                            animatedContent.push(<div className={`payment-icon-animated  ${subpaymenttype.logoname}`}><img src={'assets/paymenttypes/logos/' + subpaymenttype.logoname + '.svg'} alt={t(subpaymenttype.paymenttypenamelanguagekey)} /></div>)
                        }
                    }
                })
            }
        }
        else if (paymentType.logoname) {
            content.push(<div className={`payment-icon ${paymentType.logoname}`}><img src={'assets/paymenttypes/logos/' + paymentType.logoname + '.svg'} alt={t(paymentType.paymenttypenamelanguagekey)} /></div>);
        }
        setContent(content);
        setAnimatedContent(animatedContent);
        


    }

    return (
        <>
            {/* if no of logos <= 4 */}
            {content.map((item => { return item; }))}

            {/* if no of logos > 4 */}
            {animatedContent.length !== 0 ?
                <div className="payment-icon-animation">
                    {animatedContent.map((item, index) => {
                        return <div className={`${visibleIconIndex === index ? "show" : "hidden"}`}>
                            {item}
                        </div>
                    })}
                </div>
                : ""}
        </>
    )

}