import React, { useState, useEffect } from 'react';
import { format } from 'react-string-format';
import { useTranslation } from 'react-i18next';
import '../../i18n'
import {APIGet,APIPost} from '../API/APIRequest'
function IsSafeUrl(urlString, environment) {
    const IsSameSite = (urlString) => {
        var url = new URL(urlString);
        var currentHost = window.location.hostname;
        if (url.hostname == currentHost) {
            return true;
        }
        return false;
    }

    const IsBrandDomain = (urlString, environment) => {
        return new Promise((resolve, reject) => {
            APIGet({
                "controller": "brand",
                "action": "getcurrentbrand",
                "environment": environment
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    var cookiedomain = result.brand.cookiedomain;
                    if (cookiedomain) {
                        var url = new URL(urlString);
                        if (url.hostname.endsWith(cookiedomain)) {
                            resolve(true);
                        }
                    }
                    resolve(false);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    const IsTrustedHost = (urlString) => {
        return new Promise((resolve, reject) => {           
            var url = new URL(urlString);
            APIGet({
                "controller": "brand",
                "action": "checktrustedhost",
                "environment": environment,
                "data": {
                    "host" : url.hostname
                }
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    resolve(result.trusted);
                }
                else {
                    resolve(false);
                }
            }).catch((error) => {
                reject(error);
            });
        });
    }

    return new Promise((resolve, reject) => {
        if (IsSameSite(urlString)) {
            resolve(true);
        }
        IsBrandDomain(urlString, environment).then((result) => {
            if (result) {
                resolve(result);
            }
            else {
                IsTrustedHost(urlString, environment).then((result) => {
                    resolve(result);
                }).catch((error) => {
                    reject(error);
                });
            }
        }).catch((error) => {
            reject(error);
        });
        //resolve(false);
    });
}

function RedirectWarning(props) {

    const { t, i18n } = useTranslation();

    if (!props.isNotTrusted) {
        return ('');
    }
    return (<div><div className="url-warning-wrapper">
        <div className="url-warning-left">
        </div>
        <div className="url-warning-center">
            {format(t('auth0callback.unsafeurlwarning'), props.returnUrl)}
        </div>
        <div className="url-warning-right">
        </div>
    </div>
        <div className="redirect-buttons">
            <a className="btn" href={props.returnUrl}>{t('auth0callback.continuebutton')}</a><a className="btn" href={window.location.origin}>{t('auth0callback.cancelbutton')}</a>
        </div>
    </div>);
}

export {IsSafeUrl, RedirectWarning}