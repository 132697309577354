import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading } from '../Utilities/Loading';



export function Redirection() {
   

    const { state } = useLocation();
  
    useEffect(()=>{

        if(state){
            window.location.replace(state?.returnUrl);
        }
      

    },[state])
  

    return ( <Loading loading="true" /> )
}

