import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import "./Company.scss"
import '../../../i18n';
import { format } from 'react-string-format';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import { APIGet, APIPost } from '../../API/APIRequest';
import { CheckoutPage } from '../CheckoutPage';
import Checkbox from '../../UI/Checkbox/Checkbox';
import Dropdown from '../../UI/Dropdown/Dropdown';
import Autocomplete from '../../UI/Autocomplete/Autocomplete';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { checkIsBlank, checkIsInvalid } from '../../Utilities/FieldValidations';
import { PaymentFormContext, billingCompany } from '../../Order/PaymentFormReducer';
import MessageBox from '../../UI/MessageBox/MessageBox';
import SuccessBox from '../../UI/SuccessBox/SuccessBox';

export function Company(props) {
    const { t, i18n } = useTranslation();
    const { setVatChanged, companyProperties, setBasketErrors, selectedCompany, setBasket, purchasingFor, setIsFilled, basket, fid, vatDetails, setSelectedCompany, IsFilled, addressType, vatError, setErrorStates, IsInvalid, setIsInvalid } = props;
    const [fieldProps, setFieldProps] = useState()
    const [IsBlank, setIsBlank] = useState({ company: false, vatNo: false });
    const [IsValid, setIsValid] = useState({vatNo:basket?.taxcode ? "true" : ""})
    const [IsApplied, setIsApplied] = useState(basket?.taxcode || false)
    const [companyList, setCompanyList] = useState([]);
    const [company, setCompany] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [isChecked, setIsChecked] = useState(basket?.taxcode || false);
    const [vatNo, setVatNo] = useState(basket?.taxcode || props.vatNo || null);
  
   const companySuggestions = () => {

        APIGet({
            "controller": "company",
            "action": "getcompanysuggestions",
            "environment": null,
            "identifier": searchTerm
        }).then((response) => {
            if (response.data.success) {
                setCompanyList([...response.data.companies])
                
            }
        }).
            catch((error) => {
                var unexpected = [];
                unexpected.push("error.unexpected");

            });
    }

    // Effect to trigger debounced API call when searchTerm changes
    useEffect(() => {
        if (searchTerm) {
            companySuggestions(searchTerm);
        }
    }, [searchTerm]);

    useEffect(() => {
       
        if (companyProperties && Object.keys(companyProperties).length !== 0) {
          
            if(basket?.offertypename==="corporate subscription" && addressType==="billingAddress"){
                setFieldProps({...companyProperties, "mandatory":true})
            }
            else{
             
                setFieldProps(companyProperties)
            }
          }
    }, [companyProperties])

 
    useEffect(() => { 
       if ((company || company === "")) {
        setSelectedCompany({COMPANY: company?.name, CompanyId: company?.id || null})

        if(fieldProps?.mandatory){
         updateCompanyFilledState(company?.name)
        }}

      if(company?.id && companyAddress && addressType === "billingAddress"){
        setSelectedCompany(selectedCompany => ({ ...selectedCompany, ADDRESS: companyAddress }))
       }  

     }, [company])



 
    const handleOnVatChange = () => {
        if(vatNo){
        const commonPayload = {
            origincountrycode: basket.origincountrycode,
            destinationcountrycode: basket.destinationcountrycode,
            purchaseTarget: purchasingFor || "unknown",
        };
    
        let payload = {};
    
        if (!IsApplied) {
            payload = {
                ...commonPayload,
                taxcode: vatNo || null,
            };
         
        } else {
            payload = {
                ...commonPayload,
                taxcode: "",
                }; 
        }
    
        APIGet({
            controller: "basket",
            action: "recalculatetax",
            environment: null,
            data: payload,
            identifier: fid,
        })
        .then((response) => {
            if (response.data?.basket) {
                if (!IsApplied) {
                    setBasket(response.data?.basket);
                    setIsValid({ vatNo: "true" });
                    setSelectedCompany((selectedCompany) => ({ ...selectedCompany, vatNo: vatNo }));
                    setIsApplied(true);
                    setVatChanged(true);
                 
                    APIPost({
                        controller: "company",
                        action: "updatetaxcode",
                        environment: null,
                        data: { taxcode: vatNo },
                        identifier: company.id,
                    });
                } else { 
                    setVatNo("");
                    setBasket(response.data?.basket);
                    setIsValid({ vatNo: "" }); //initial value
                    setSelectedCompany((selectedCompany) => ({ ...selectedCompany, vatNo: "" }));
                    setIsApplied(false);
                    setVatChanged(false);
                }
            }
        })
        .catch((error) => {
            if (error?.response?.data?.errors) {
                setIsValid({ vatNo: "false" });
            }

            if (error.response && error.response.status === 400)  {
                const errorData = error.response.data;
        
                if (errorData.errors && errorData.errors.includes('error.basketcompletedorder')) {
                  // Handling the specific error related to a completed order
                  setBasketErrors(errorData.errors);
                  setBasket({});
               
                } 
                
              } 
          });
    }else{
        setIsValid({vatNo: "empty"})
    }
        props.isVatApplied(!IsApplied);
   
    };
    
    const handleOnBlur = (e) => {

      
        if (e.target.value && checkIsInvalid(e.target.value, "address")) {
            setIsInvalid(IsInvalid => ({ ...IsInvalid, COMPANY: { invalid: true, mandatory: true } }))
        }
        else {
            setIsInvalid(IsInvalid => ({ ...IsInvalid, COMPANY: { invalid: false, mandatory: true } }))
        }
        if (fieldProps.mandatory) {
           updateCompanyFilledState(e.target.value);
        }
        if (!vatNo || vatNo.length === 0) {
            setIsValid({ vatNo: "" })
        }
    }

    const updateCompanyFilledState=(value)=>{
          if (value.length > 0) {
                setIsFilled(IsFilled => ({ ...IsFilled, COMPANY: { filled: true, mandatory: true } }))
            }
            else {
                setIsFilled(IsFilled => ({ ...IsFilled, COMPANY: { filled: false, mandatory: true } }))}
             }

    const handleVatInput = (e) => {
        let value = e.target.value;
        if (!IsApplied) {
            if (value) {
                setVatNo(e.target.value)
                setIsValid({ vatNo: "" }) 
           
            } else {
                setVatNo(null)
               // props.isVatApplied(IsApplied);
            }
           
        }
    }

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked; // Get the actual checkbox value
        setIsChecked(!isChecked); // Update the local state
     
        //setIsChecked(!isChecked)
        //pass the vat check to grandparent - payment - VA
        props.isVatChecked(checked);
        if (checked) {
            setFieldProps({ ...companyProperties, "mandatory": true })
        } else {
            setFieldProps({ ...companyProperties})
        }
       
    };


   useEffect(()=>{
        if((basket?.origincountrycode || basket?.destinationcountrycode) && vatNo){
            handleOnVatChange();  //anytime country changes Vatcode needs to be emptied
        }
      
    }, [basket?.origincountrycode, basket?.destinationcountrycode])    


    useEffect(() => {
        if (IsApplied) { 
            setErrorStates(errorData => ({ ...errorData, isVatEmpty: false }))  
        }
   
    }, [IsApplied])


  return (<>
   
        <div className="item-container">
            <div className="item-wrapper">
                {/*when company list array has items */}

                <Autocomplete
                    label={fieldProps && fieldProps.label }
                    id={fieldProps && fieldProps.fieldName}
                    name={fieldProps && fieldProps.fieldName}
                    placeholder={fieldProps?.placeHolderText}
                    type="text"
                    inputFieldType="text"
                    invalidClass={(!isChecked ? (IsFilled?.COMPANY && !IsFilled.COMPANY?.filled && fieldProps?.mandatory && "Invalid-input") : (selectedCompany?.COMPANY?.length <= 0 || selectedCompany?.COMPANY?.length === undefined) && "Invalid-input") || (IsInvalid?.COMPANY && IsInvalid.COMPANY?.invalid && "Invalid-input")}
                    aria-invalid={(IsFilled?.COMPANY && !IsFilled.COMPANY?.filled && fieldProps?.mandatory) || (IsInvalid?.COMPANY && IsInvalid.COMPANY?.invalid)}
                    aria-describedby={`${[fieldProps?.fieldName]}-errors`}
                    optionalField={!fieldProps?.mandatory}
                    onBlurFunction={(e) => {handleOnBlur(e)}}
                    inputSize="full-width"
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setValue={setCompany}
                    setAddress={setCompanyAddress}
                    propValue={selectedCompany?.COMPANY}
                    options={companyList}
                    dropdownName="company"
                />
              
                {(((IsFilled?.COMPANY && !IsFilled?.COMPANY?.filled) && fieldProps?.mandatory && !isChecked)) && 
                    <span role="alert" id={`${[fieldProps?.fieldName]}-errors`}> 
                            <ErrorBox>{fieldProps ? format(t('addressfields.blankerrortext'), fieldProps?.label) : t('biilingdetails.blankcompany')}</ErrorBox>
                    </span>}
                {(isChecked && (selectedCompany?.COMPANY?.length <= 0 || selectedCompany?.COMPANY?.length === undefined)) &&
                    <span role="alert" id={`${[fieldProps?.fieldName]}-errors`}>
                        <ErrorBox>{fieldProps ? format(t('addressfields.blankerrortext'), fieldProps?.label) : t('biilingdetails.blankcompany')}</ErrorBox>
                    </span>}
                {(IsInvalid[fieldProps?.fieldName]?.invalid) &&
                    <span role="alert" id={`${[fieldProps?.fieldName]}-errors`}>
                        <ErrorBox>{format(t('addressfields.errortext'), fieldProps?.label)}</ErrorBox>
                    </span>}
            </div>

            {addressType === "billingAddress"  && vatDetails.showTaxCode && vatDetails.taxCodeFormat  ?
                <div className="item-wrapper">
                    <InputWrapperRefacture
                        labelText={t('checkout.taxtext')}
                        id="taxquestion"
                        name="taxquestion"
                        inputWidth="small"
                        type="text"
                        inputFieldType="checkbox"
                        checked={!isChecked}
                        onChange={handleCheckboxChange}
                    />
                   
                </div>
                : ""
            }
         </div>

      
        {addressType === "billingAddress"  && vatDetails.showTaxCode && vatDetails.taxCodeFormat && isChecked ? 
            <div className="item-container">
                <div className="item-wrapper">
           
                    <InputWrapperRefacture
                        label={t('checkout.vatlabel')}
                        id="vatNo"
                        name="vatNo"
                        type="text"
   
                        onClickFunction={()=>handleOnVatChange()}  //the edit button should only show when psw-requires is on
                        btnText={!IsApplied ? t('checkout.vatapplybuttonlabel') : t('checkout.vatremovebuttonlabel')}
                        onBlur={(e) => { handleOnBlur(e, "vatNo") }}
                        onChange={(e) => { handleVatInput(e) }}
                        inputFieldType="text"
                        invalidClass={(props.errorStates?.isVatEmpty || IsBlank?.vatNo || IsValid?.vatNo==="false" || IsValid?.vatNo==="empty" ) && isChecked && "Invalid-input"}
                        aria-invalid={(props.errorStates?.isVatEmpty || IsBlank?.vatNo || IsValid?.vatNo==="false" || IsValid?.vatNo==="empty") && isChecked}
                        aria-describedby="vatNo-error"
                        inputSize="small"
                        value={vatNo || selectedCompany.vatNo} />
                        <span role="alert" id='vatNo-error'>
                        {IsValid.vatNo==="false" && <ErrorBox>{t('basket.invalidtaxcode')}</ErrorBox>}
                        {(IsValid.vatNo === "empty" || (props.errorStates?.isVatEmpty && IsValid.vatNo === '')) && <ErrorBox>{t('basket.blanktaxcode')}</ErrorBox>} 

                        {IsValid.vatNo==="true" && <SuccessBox>{t('basket.taxcodeapplied')}</SuccessBox>}
                    </span>
                </div>

            </div>
            
            : ""}

    </>
    );
}

