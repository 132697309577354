import axios from 'axios';
import secretStore from './secret.json';

export { APIGet, APIPost, APIRedirect, getEnvironment, getPage, APIRootDomain, APIUrl };
function APIGet(props) {
 
    return new Promise((resolve, reject) => {
        var apiUrl = APIUrl(props);
        if (props.data) {
            var params = new URLSearchParams(props.data).toString();
            apiUrl += "?" + params;
        }

        let headers = {};
        if (props.headers) {
            for (let i = 0; i < props.headers.length; i++)
            {
                let headerkv = props.headers[i];
                headers[headerkv.key] = headerkv.value;
            }
        }
        headers["Authorization"] = "Bearer " + secretStore.secret;
       
        axios({
            method: "get",
            url: apiUrl,
            headers: headers 
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        })
    });
}

function APIPost(props) {
  
    return new Promise((resolve, reject) => {
        var apiUrl = APIUrl(props);
        var contentTypeHeader = "multipart/form-data";
        if (props.content_type && props.content_type == "json") {
            contentTypeHeader = "application/json";
        }
        let headers = {};
        if (props.headers) {
            for (let i = 0; i < props.headers.length; i++) {
                let headerkv = props.headers[i];
                headers[headerkv.key] = headerkv.value;
            }
        }

        headers["Authorization"] = "Bearer " + secretStore.secret;
        headers["Content-Type"] = contentTypeHeader;

        axios({
            method: "post",
            data: props.data,
            url: apiUrl,
            headers: headers,
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        })
    });
}

function APIRedirect(props) {
    var apiUrl = APIUrl(props);
    if (props.data) {
        var params = new URLSearchParams(props.data).toString();
        apiUrl += "?" + params;
    }
    window.location.href = apiUrl;
}

function APIRootDomain() {

    var apiUrl = window.location.protocol + "//" + window.location.host;

    var port = window.location.port;
    // this stuff below is for local development environments
    if (port !== "80" && port !== "443" && port !== "") {
        apiUrl = window.location.protocol + "//" + window.location.hostname + ":7150"
    }
    return apiUrl;
}

function APIUrl(props) {

    var apiUrl = window.location.protocol + "//" + window.location.host;

    var port = window.location.port;
    // this stuff below is for local development environments
    if (port !== "80" && port !== "443" && port !== "") {
        apiUrl = window.location.protocol + "//" + window.location.hostname + ":7150"
    }

    // back to the regular things
    var controller = new String(props.controller);
    var action = new String(props.action);
    apiUrl += "/api/" + controller;
    if (props.environment) {
        apiUrl += "/" + props.environment;
    }
    apiUrl += "/" + action;
    if (props.identifier) {
        apiUrl += "/" + props.identifier;
    }
    return apiUrl;
}

function getEnvironment() {

    var path = window.location.pathname;
    var environment = null;
    if (path.length > 1) {
        path = path.substring(1);
        var nextSegmentIndex = path.indexOf("/");
        if (nextSegmentIndex > 0) {
            var environmentCandidate = path.substring(0, nextSegmentIndex);
            if (environmentCandidate.indexOf(".") > 0) {
                environment = environmentCandidate;
            }
        }
    }
    return environment;
}


function getPage() {
    let path = window.location.pathname;
    let page = path;
    if (path.length > 1) {
        path = path.substring(1);
        page = path;
        var nextSegmentIndex = path.lastIndexOf("/");
        if (nextSegmentIndex > 0) {
            page = path.substring(nextSegmentIndex + 1);
        }
    }
    return page;
}


