import { Fragment } from "react";
import Button from "../Buttons/Button";
import Input from '../Inputs/InputWrapperRefacture';
import InputWrapper from '../Inputs/InputWrapper';
import MessageBox from "../MessageBox/MessageBox";
import { ProductOffer } from "../../ProductOffer/ProductOffer";

const DesignReview = () => {
    return <>
       <div className="container">
          <section>
            
            <MessageBox messageState="warning">This is a warning</MessageBox>
            <br />
            <MessageBox messageState="error">This is an error message</MessageBox>
            <br />
            <MessageBox messageState="info">This is an info message</MessageBox>
            <br />
            <MessageBox messageState="good">This is a positive message</MessageBox>
            <br />
            <MessageBox messageState="neutral">This is a neutral message</MessageBox>
            <br />
            </section>
        <section>
        <h1> This is an H1</h1>
        <h2> This is an H2</h2>
        <h3> This is an H3</h3>
        <h4> This is an H4</h4>
            <h5> This is an H5</h5>
        </section>
        <section>
            <h1 className="module-heading"> Module heading </h1>
            <h2 className="module-subheading"> Module sub-heading </h2>
            <p className="module-description"> Module description </p>

        </section>
        <section>
            <div>
                <Button buttonSize="full-width"> Click me! </Button> <br /> 
                <Button buttonSize="large"> Click me! </Button><br />
                <Button buttonSize="medium"> Click me! </Button><br />
                <Button buttonSize="small"> Click me! </Button><br />
            </div>
            <div>
                <Button buttonSize="full-width" hasIcon="true"> Click me! </Button> <br />
                <Button buttonSize="large" hasIcon="true"> Click me! </Button><br />
                <Button buttonSize="medium" hasIcon="true"> Click me! </Button><br />
                <Button buttonSize="small" hasIcon="true"> Click me! </Button><br />
            </div>
            <div>
                <Button buttonSize="full-width" hasOnlyIcon="true">  </Button> <br />
                <Button buttonSize="large" hasOnlyIcon="true"> </Button><br />
                <Button buttonSize="medium" hasOnlyIcon="true"></Button><br />
                <Button buttonSize="small" hasOnlyIcon="true"> </Button><br />
            </div>
            <div>
                <Button buttonSize="full-width" isDisabled="true"> Click me! </Button> <br />
                <Button buttonSize="large" isDisabled="true">Click me! </Button><br />
                <Button buttonSize="medium" isDisabled="true">Click me!</Button><br />
                <Button buttonSize="small" isDisabled="true">Click me! </Button><br />
            </div>
        </section>
        <section>
            <Input
                inputFieldType="text"
                id="email"
                edit
                label="Label Input text "
                inputSize="full-width"
                placeholder="Input Text"
                description="This is the label description"
                errorMessage="this is an error"
                btnText="Edit" 
                optionalField
                helpField
            /> 
            <Input
                inputFieldType="password"
                id="password" 
                inputSize="full-width"
                placeholder="addthepassword" 
                errorMessage="this is an error" 
            /> 

     
        </section>

        <section>

            Input wrapper starts here

        </section>
        <section>

            <InputWrapper
                edit 
                label="label"
                inputSize="full-width"
                inputType="text"
                specs="text_input_with_help"
                id="email"
                name="email" placeholder="Enter your email address"
                btnText="Edit"
                optionalField 
                helpField 
              />

            <InputWrapper inputType="radio" specs="radio_and_checkbox_input" label="Radio" />
            <br />
            <InputWrapper inputType="checkbox" specs="radio_and_checkbox_input" label="Checkbox" />
            <br />
            <InputWrapper inputType="checkbox" specs="toggle_switch" label="Notifications" />
            <br />
            <InputWrapper inputType="radio" label="radio_input_with_description" specs="radio_input_with_description" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, incididunt ut labore et dolore magna aliqua" />
            <br />
            <InputWrapper inputType="checkbox" label="Checkbox with Description" specs="radio_input_with_description" description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam," />

            <br />
            <InputWrapper inputType="checkbox" label="Checkbox with Border" specs="radio_input_with_border" />
            <br />
            <InputWrapper inputType="radio" label="Radio with Border" specs="radio_input_with_border" />
            <br />
            <InputWrapper inputType="checkbox" label="Checkbox with Border" specs="radio_input_with_border" />
            <br />
            <InputWrapper inputType="radio" label="Radio with AltText" specs="radio_input_with_altText" altText="ALT txt" />
            <br />
            <InputWrapper inputType="checkbox" label="Checkbox with AltText" specs="radio_input_with_altText" altText="ALT txt" />
            <br />
            <InputWrapper inputType="checkbox" specs="checkbox_input_for_amount" label="5$" subscriptionAmount="5$" subscriptionPeriod="Annually" />
        </section>
       
      

    </div>
     
     
     
    </>
}
export default DesignReview;