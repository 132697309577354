import { useEffect, useState} from 'react';

//go through for error handling

export function DynamicScriptComponent({ metaData, branding}) {
	
    const [metaScriptsAdded, setMetaScriptsAdded] = useState(false);
    const [brandingScriptsAdded, setBrandingScriptsAdded] = useState(false);

    const extractingScript = (data) => {
        const scripts = [];
        if (data) {

            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = data;
            let scriptTags = tempDiv.getElementsByTagName('script');

            for (let i = 0; i < scriptTags.length; i++) {
                let scriptContent = scriptTags[i].textContent || scriptTags[i].innerText;
                let srcAttributeValue = scriptTags[i].getAttribute('src');
                let scriptId = scriptTags[i].id;
                let scriptType = scriptTags[i].type;
                scripts.push({ "scriptContent": scriptContent, "src": srcAttributeValue, "id":scriptId, 'type':scriptType });
            }
        }
        return scripts;
    }

    //element=where to append
    //pos= the position in the element
    const executingScripts = (scripts, element, pos) => {
        scripts.forEach(script => {
            const newScript = document.createElement('script');

            if(script.id){
                newScript.id = script.id;
            }
            if(script.type){
                newScript.type = script.type; 
            }
          
            if (script.src) {
                newScript.src = script.src;
            } else {
                newScript.textContent = script.scriptContent;
            }

            if (element === "body") {
                document.body.insertAdjacentElement(pos, newScript);
            }
            else if (element === "head") {
                document.head.insertAdjacentElement(pos, newScript);
            }
           
        });
    }


    const removingScripts = (addedScripts) => {
        addedScripts.forEach(script => {
            if (script.src) {
                const existingScript = document.querySelector(`script[src="${script.src}"]`);
                if (existingScript) {
                    existingScript.remove();
                }
            } else if (script.scriptContent) {
                
                const existingScripts = document.querySelectorAll(`script`);
                existingScripts.forEach(existingScript => {
                    if (existingScript.textContent === script.scriptContent) {
                        existingScript.remove();
                    }
                });
            }
        });
    };

    useEffect(() => {

        if (!brandingScriptsAdded && branding) {

            try {

                const headHtmlScript = extractingScript(branding?.headHtml);
                executingScripts(headHtmlScript, "head", "beforeend");

                const afterBodyOpenScript = extractingScript(branding?.afterBodyOpenData);
                executingScripts(afterBodyOpenScript, "body", "afterbegin");

                const beforeBodyCloseScript = extractingScript(branding?.beforeBodyCloseData);
                executingScripts(beforeBodyCloseScript, "body", "beforeend");

                setBrandingScriptsAdded(true)

                return () => {
                    removingScripts(headHtmlScript);
                    removingScripts(afterBodyOpenScript);
                    removingScripts(beforeBodyCloseScript);

                };

            } catch (error) {
                console.error('Error in appending the script. Please check if the call is taking place correctly', error);
            }
        }

    }, [branding]);


    useEffect(() => {
        
      if (!metaScriptsAdded && metaData) {
            try {

                const partyMetaDataScript = extractingScript(metaData?.partymetadata);
                executingScripts(partyMetaDataScript, "head", "afterbegin");

                const basketMetaDataScript = extractingScript(metaData?.basketmetadata);
                executingScripts(basketMetaDataScript, "head", "afterbegin");

                setMetaScriptsAdded(true)

                return () => {
                    removingScripts(basketMetaDataScript);
                    removingScripts(partyMetaDataScript);
                    
                };

            } catch (error) {
                console.error('Error in appending the script. Please check if the call is taking place correctly', error);
            }
        }

    }, [metaData]);

    return (

        <></>

    );
}