export {gtmEventTriggers};

function addBrandName(){
  let brandName= sessionStorage.getItem("brandName");
  window.checkoutVariants.forEach(function(variant) {
    variant.brand = brandName;
  });
}

function addToCartGTM(){
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_to_cart',
    category: 'Cart Information',
    action: 'Clicked Button',
    });
}

function removeFromCartGTM(){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'remove_from_cart',
      category: 'Cart Information',
      action: 'Clicked Button',
      });
  }

function beginCheckoutGTM(){
  try{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'begin_checkout',
      category: 'Checkout Process',
      action: 'Clicked Button',
      currency:window.checkoutCurrency,
      items:window.checkoutVariants,
      value:window.checkoutPrice,
      });
    }catch(e){
      console.log("Variables needed for begin checkout event not present")
    }
  }
  

  function addShippingInfoGTM(){
    try{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'add_shipping_info',
      category: 'Shipping Information',
      action: 'Clicked Button',
      currency:window.checkoutCurrency,
      items: window.checkoutVariants,
      value:window.checkoutPrice,
      });
    }
    catch(e){
      console.log("Variables needed for add shipping info event not present")
    }

  }

  
  function addBillingInfoGTM(){
    try{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'add_billing_info',
      category: 'Billing Information',
      action: 'Clicked Button',
      currency:window.checkoutCurrency,
      items:window.checkoutVariants,
      value:window.checkoutPrice,
      });
    }catch(e){
      console.log("Variables needed for billing info event not present")
    }
  }


  function addPaymentInfoGTM(){
    try{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'add_payment_info',
      category: 'Payment Information',
      action: 'Clicked Button',
      currency:window.checkoutCurrency,
      items:window.checkoutVariants,
      value:window.checkoutPrice,
      });
    }
    catch(e){
      console.log("Variables needed for add payment info event not present")
      
    }
  }


  function purchaseGTM(checkoutOrderId){
    try{
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
         event: 'purchase',
         category: ' Information',
         action: 'Clicked Button',
         currency:window.checkoutCurrency,
         items: window.checkoutVariants,
          transaction_id: checkoutOrderId,
          value: window.checkoutPrice,
          shipping: window.checkoutShippingPrice,
          tax: window.checkoutTax
      });
    }catch(e){
       console.log("Variables needed for purchase event not present")
    } 
   
    }


  function gtmEventTriggers(name, orderid){

   if(window.checkoutVariants){
   
    addBrandName();
   }
    
   if(window.checkoutVariants){
      if(name==="begin_checkout"){
       
        beginCheckoutGTM();
        
      }
      if(name==="add_shipping_info"){
       
        addShippingInfoGTM();
      }
      if(name==="add_billing_info"){
        addBillingInfoGTM();
        
      }
      if(name==="add_payment_info"){
        addPaymentInfoGTM();

      }
      if(name==="purchase"){
        purchaseGTM(orderid);

      }
     }    
   }