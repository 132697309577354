import "./Promo.scss"
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import PromoCode from "../PromoCode/Promocode";


export function Promo() {

        const [selectedTheme, setSelectedTheme]= useState("Theme1")
        const [buttons, setButtons]= useState([{id:"Theme1",value:"Theme1"}, {id:"Theme2",value:"Theme2"}, 
                                               {id:"Theme3",value:"Theme3"}, {id:"Theme4",value:"Theme4"}])

      
        const Theme1 = () => {
        return <div className="theme1-promo-container">
                   <div className="item-container">
                       <h1>Title text goes here </h1>
                       <p>Simple text box can be manipulated to have as much or little text as possible</p>
                       <div className="item-container">
                            <div>
                              <h2>Subtitle text can go here within the text box</h2>
                            </div>
                            <ul className="item-wrapper">
                               <li><span className="checked"></span>
                                  <p>Get unlimited access to our website, with 100s of new articles added each month with lots of other stuff.</p>
                               </li>
                               <li><span className="checked"></span>
                                  <p>Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage.</p>
                               </li>
                            </ul>
                       </div>
                  <div className="promo-code-container">
                    <PromoCode/>
                  </div>
             </div>
             <img src='https://mobimg.b-cdn.net/v3/fetch/62/624e27fde335d49e2dd3c6b75c6027a3.jpeg' alt="promo image" />
        </div>
    }

    const Theme2 = () => {
    return <div className="theme2-promo-container">
              <div>
                  <div className="item-container">
                      <h3>Title text goes here </h3>
                  </div>
                  <ul className="item-container">
                      <li><span className="blue-circle"><span className="calendar-icon"></span></span><p>Get unlimited access to our website, with 100s of new articles added each month with lots of other stuff.</p></li>
                      <li><span className="blue-circle"><span className="info-icon"></span></span><p>Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage.</p></li>
                  </ul>
              </div>
             <img src='https://mobimg.b-cdn.net/v3/fetch/62/624e27fde335d49e2dd3c6b75c6027a3.jpeg' alt="promo image" />
        </div>
    }

    const Theme3 = () => {
    return <div className="theme3-promo-container">
              <img src='https://mobimg.b-cdn.net/v3/fetch/62/624e27fde335d49e2dd3c6b75c6027a3.jpeg' alt="promo image" />
              <div className="promo-div-wrapper">
                  <h3>Title text goes here </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non consectetur arcu, porta nunc tellus ut facilisi fringilla. Arcu, molestie consectetur sed gravida sagittis blandit. Ultrices ante blandit tempus praesent parturient sollicitudin ut vulputate.
                     Ultrices malesuada mauris turpis eget. Sed diam pellentesque in viverra vel tristique nisi. </p>
              </div>
          </div>
    }

    const Theme4 = () => {
    return <div className="theme4-promo-container">
              <span className="promo-image"> 
                   <div className="promo-div-wrapper">
                        <h3>Title text goes here </h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non consectetur arcu, porta nunc tellus ut facilisi fringilla. Arcu, molestie consectetur sed gravida sagittis blandit. Ultrices ante blandit tempus praesent parturient sollicitudin ut vulputate.
                         Ultrices malesuada mauris turpis eget. Sed diam pellentesque in viverra vel tristique nisi. </p>
                   </div>
              </span>
         </div>
    }

    const Theme5 = () => {
    return <div className="theme5-promo-container">
                  <img src='https://mobimg.b-cdn.net/v3/fetch/62/624e27fde335d49e2dd3c6b75c6027a3.jpeg' alt="promo image" />
                  <h1>Title text goes here </h1>
               <div>
                   <div className="item-container">
                      <p>Simple text box can be manipulated to have as much or little text as possible</p>
                   </div>
                   <div className="item-container">
                       <div>
                       <h2>Subtitle text can go here within the text box</h2>
                       </div>
                       <ul className="item-wrapper">
                          <li><span className="checked"></span>
                              <p>Bullet point with icon</p>
                          </li>
                          <li><span className="checked"></span>
                              <p>Bullet point with icon</p>
                          </li>
                      </ul>  
                  </div>
              </div> 
              <div className="promo-code-container">
                 <PromoCode/>
              </div>   
           </div>
    }
  

    const Theme6 = () => {
        return <div className="theme6-promo-container">
                      <div>
                          <h1>Title text goes here </h1>
                      </div>
                   <div>
                      <div className="item-container">
                          <p>Simple text box can be manipulated to have as much or little text as possible</p>
                      </div>
                      <div className="item-container">
                          <div>
                              <h2>Subtitle text can go here within the text box</h2>
                          </div>
                            <ul className="item-wrapper">
                               <li><span className="checked"></span>
                                   <p>Bullet point with icon</p>
                              </li>
                               <li><span className="checked"></span>
                                    <p>Bullet point with icon</p>
                               </li>
                           </ul>  
                      </div>
                  </div>    
                  <div className="promo-code-container">
                       <PromoCode/>
                  </div>
             </div>
        }

    
      
    return <> {/*this map logic is for testing the flow of themes, will be removed as we get the data from db. That's why have used inline styling*/}

        {/* <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            {buttons.map((item, index) => {
                return <button key={index} onClick={() => setSelectedTheme(item.id)}>{item.value}</button>
            })}
        </div> */}
     {/* {selectedTheme === "Theme1" ? Theme1()
            : selectedTheme === "Theme2" ? Theme2()
                : selectedTheme === "Theme3" ? Theme3()
                    : selectedTheme === "Theme4" ? Theme4() :
                        ""} */}
             {Theme1()}
             <br/>
             <br/>
             {Theme2()}
             <br/>
             <br/>
             {Theme3()}
             <br/>
             <br/>
             {Theme4()}
             <br/>
             <br/>
             {Theme5()}
             <br/>
             <br/>
             {Theme6()}
             <br/>
             <br/>
         </>
    }

