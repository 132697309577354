import React, { useState, Suspense } from 'react';
import { Loading } from './components/Utilities/Loading'
import { getEnvironment } from './components/API/APIRequest'
import {GetClient} from './components/Configuration/ConfigurationManager'
const Client = props => {
    const [client, setClient] = useState(null);
    var environment = getEnvironment();
    GetClient(environment).then((response) => {
        setClient(response);
    }).catch((error) => {
        setClient("none");
    });

    var content = '';
    if (client) {
        if (client !== "none") {
            content = props.children;
        }
        else {
            content = <div className="center-column"><h3>No Configuration</h3><div>No configuration can be found for {(environment ? environment : window.location.host)}</div></div>
        }
    }
  
    return (<div>
        <Loading loading={!client} />
        {content}
    </div>)
}

export default Client;