import React from 'react';
import { useTranslation } from 'react-i18next';
import "./../ThankyouPage.scss";
import "../../../../general.scss";
import DOMPurify from 'dompurify';

export function UserSignin(props) {
    const definedURL = sessionStorage.getItem("postcompleteurl-default");
    const { t, i18n } = useTranslation();
    const {customThankyouMessage, customButtonText}= props;
    

    return (
        <div className="item-container">
            <div className="with-button">
                <div className="item-container">
                   
                    {customThankyouMessage.show === "true" ? <p className="large subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(customThankyouMessage.text) }}></p> :
                     customThankyouMessage.show === "false" ? <p className="large subtext">{t("thankyoucontinue.message")}</p> :""}
                   </div>
                <a className="primary-button" href={definedURL} > {customButtonText.show ==="true" ? customButtonText.text : 
                 customButtonText.show ==="false" ? t("thankyoucontinue.buttontext") : ""}</a>
            </div>
        </div>
    )
}



