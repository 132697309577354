import { forwardRef, Fragment, useState } from "react";
import {Link} from "react-router-dom";
import InputNew from "./InputNew.js";
//import './Input.css';
//import { shouldProcessLinkClick } from "react-router-dom/dist/dom";
const InputWrapper = (props) => {

    const { description, altText,edit, type, inputType, inputSize, label, details,btnText, showBtnText, hideBtnText, btnHandler, helpText, specs, subscriptionAmount, subscriptionPeriod } = props;

    const [showHelp, setShowHelp] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [editable, setEditable]=useState(false)
    //switch case for different Input Components
    const getInputUIForSpecs = () => {
                switch (specs) {
                    case 'radio_and_checkbox_input':
                        return radio_and_checkbox_input()
                    case 'toggle_switch':
                        return toggle_switch()
                    case 'radio_input_with_altText':
                        return radio_input_with_altText()
                    case 'radio_input_with_description':
                        return radio_input_with_description()
                    case 'text_input_with_button':
                        return text_input_with_button()
                    case 'text_input_with_help':
                        return text_input_with_help()
                    case 'radio_input_with_border':
                        return radio_input_with_border()
                    case 'text_input':
                        return text_input()
                    case 'checkbox_input_for_amount':
                        return checkbox_input_for_amount()
                    case 'text_input_for_details':
                        return text_input_for_details()
                case 'password_input_with_button':
                        return password_input_with_button()
                    default:
                            return text_input()     



        }
    }

    const getInputUIForType=()=>{
        switch (type){
           case 'text':
               return text_input()
                   case 'password':
                       return password_input_with_button()
                           case 'radio':
                               return radio_and_checkbox_input()
                                   case 'checkbox':
                                       return radio_and_checkbox_input()
                                           case 'email':
                                                return text_input()
                                                  default :
                                                    return text_input()
                       

                         

        

        }

    }



    //radio and checkbox inputs in the application
    const radio_and_checkbox_input = () => {
        return <div className="radio-div" >
            <InputNew type={inputType} label={props.label}  {...props} />
            <label className={`${props.inputType} fw-400 fs-16`} htmlFor={props.id}> {props.label} </label>
        </div>
    }

    //switch inputs in the application
    const toggle_switch = () => {
        return <div className="toggle-switch-div flex-direction-row flex-space-between">
            <p className="mt-6 font-fam">{label}</p><label class="switch">
                <InputNew type={inputType}  label={props.label}  {...props} />
                <span class="slider round"></span>
            </label>
        </div>
    }

    //radio and checkbox inputs with altText 
    const radio_input_with_altText = () => {
        return <div className="radio-border pd-12 mb-6">
            {radio_and_checkbox_input()}
            <p className="altText" >{altText}</p>
        </div>

    }

    //radio and checkbox inputs with description
    const radio_input_with_description = () => {
        return <div className="radio-Noborder mt-6 mb-6">
            {radio_and_checkbox_input()}
            {specs === "radio_input_with_description" && description && <p className="fw-400 fs-14 fc-grey description">{description}</p>}
        </div>

    }

    //radio and checkbox inputs with border
    const radio_input_with_border = () => {
        return <div className="radio-border pd-12">
            {radio_and_checkbox_input()}
        </div>

    }

    //text and psw input with a button
    const text_input_with_button = () => {
        return <div className="text-div  ">
            <label className={`${props.inputType} fw-600 fs-16`} htmlFor={props.id}> {props.label} </label>
            {(props.specs === "search_input") && <p className="font-fam fs-14 fc-grey mb-6">Type first letters to find..</p>}
            <div className="pos-relative">
                <InputNew inputSize={`${inputSize} pos-absolute`} type={inputType} disabled={edit ? !editable : false} label={label}  {...props} />
                {edit? <Link  className="border-0 bg-none pos-absolute top-15 right-5 btn-in-input" to={props.route}>{btnText}</Link>:""}
            </div>
        </div>
        
    }
        //text and psw input with a button------to be edited later
        const text_input_with_button1 = () => {
            return <div className="text-div  ">
                <label className={`${props.inputType} fw-600 fs-16`} htmlFor={props.id}> {props.label} </label>
                {(props.specs === "search_input") && <p className="font-fam fs-14 fc-grey mb-6">Type first letters to find..</p>}
                <div className="pos-relative">
                    <InputNew inputSize={`${inputSize} pos-absolute`} type={inputType} disabled={edit ? !editable : false} label={label}  {...props} />
                    {edit? <button type='button'  className="border-0 bg-none pos-absolute top-15 right-5 btn-in-input" onClick={()=>{setEditable(true)}}>{btnText}</button>:""}
                </div>
            </div>
        }

    //psw input
    const password_input_with_button = () => {
        return <div className="text-div  ">
            <label className="password fw-600 fs-16" htmlFor={props.id}> {props.label} </label>
            {(props.specs === "search_input") && <p className="font-fam fs-14 fc-grey mb-6">Type first letters to find..</p>}
            <div className="pos-relative">
   
                <InputNew  inputSize={`${inputSize} pos-absolute`}   label={label}  {...props} inputType={showPassword ? "text" : "password"}  />
                <button type='button'  className="border-0 bg-none pos-absolute top-15 right-5 btn-in-input" onClick={(e)=>{setShowPassword(!showPassword)}}>{showPassword? "Hide" : "Show"}</button>
            </div>
        </div>
    }

    //text inputs with a help text which toggles with a button
    const text_input_with_help = () => {
        return <div className="text-div flex-direction-column" style={{maxWidth:"578px"}}>
            <div className="flex-direction-row flex-space-between">
                <label className={`${props.inputType} fw-600 fs-16`} htmlFor={props.id}> {props.label} </label>
                {getHelpTextUI()}
            </div>
            <p className="font-fam fs-14 fc-grey mb-6">Type first three letters to find..</p>
            {showHelp && helpText && <div className="help-text-div mt-6 mb-6 pd-12 ">
                <p className="help-text-question fs-14 fw-700 fc-black" >About this question</p>
                <p className="help-text-answer fs-14 fw-400 fc-grey" >{helpText}</p>
            </div>}
           
                <InputNew inputSize={inputSize} type={inputType}  label={label}  {...props} />
           
        </div>

    }


    //text inputs for details which toggles with a button
    const text_input_for_details = () => {
        return <div className="flex-direction-column border-left-2 pd-left-14 ">
            <div className="flex-direction-row flex-start ht-32">
                <p className="fs-14 fc-blue fw-700 font-fam mt-6 ">Tell me more about this</p>
                <button type='button'  className="hide-btn" onClick={() => { setShowDetails(!showDetails) }}><i class="fa fa-angle-down fc-blue fw-700 fs-14"></i></button>
            </div>

            {showDetails && details && <div className="dropdown-text-div "> <p className="fs-14 fc-black">{details}</p> </div>}

        </div>

    }

    //basic text input with a label along with a standard subtext with an exception of email input box
    const text_input = () => {
        return <div className="text-div  ">
            <label className={`${props.inputType} fw-600 fs-16`} htmlFor={props.id}> {props.label} </label>
            {(props.specs === "search_input") && <p className="font-fam fs-14 fc-grey mb-6">Type first letters to find..</p>}
           
                <InputNew inputSize={inputSize} type={inputType || type}  label={label}  {...props} />
          
        </div>

    }

  


    //returns the ui of help text section in text_input_with_help
    const getHelpTextUI = () => {
        return <div className="flex-direction-row flex-end align-center wd-50">
            <span className="optional-text fs-16">Optional |</span>
            <button type='button'  className="help-text-btn" onClick={() => setShowHelp(!showHelp)}>
               <span className={!showHelp ? "question-icon" : "close-icon"} ></span>
            </button>

        </div>


    }


    //checkbox input for selection of subscription amount and cycle
    const checkbox_input_for_amount = () => {
        return <div className="checkbox_input_for_amount flex-direction-row flex-space-between">
            <div className="flex-direction-column ">
                <label className={`${props.inputType} font-fam fs-16 fc-black`} htmlFor={props.id}> {subscriptionAmount} </label>
                <p className="font-fam fs-14 fc-lightGrey">{subscriptionPeriod}</p>
            </div>
            <InputNew type={inputType}  {...props} />

        </div>
    }



    return <Fragment>

        {!specs ? getInputUIForType : getInputUIForSpecs() }
    </Fragment>
}
export default InputWrapper;