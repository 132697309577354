import React, { useState, Fragment } from "react";
import ReactHtmlParser from 'html-react-parser';

const HeaderBranding = props => {
    const headerHtml = props?.brandingHeader || '';
    return (
        <Fragment>
            {ReactHtmlParser(headerHtml)}
        </Fragment>
       )
    }
export default HeaderBranding;

