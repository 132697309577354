import { APIGet } from '../API/APIRequest';

export function GetConfiguration (configurationName, environment) {
    return new Promise((resolve, reject) => {
        var setting = sessionStorage.getItem(configurationName);
        var configuration = null;
        if (!setting) {         
            APIGet({
                "controller": "configuration",
                "action": configurationName,
                "environment": environment
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    configuration = result.configuration;
                    sessionStorage.setItem(configurationName, JSON.stringify(configuration));
                    resolve(configuration);
                }
                else
                {
                    reject(result.errors);
                }
                //return result.authprovidertype;
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(JSON.parse(setting));
        }
    });
}

export function GetClient(environment) {
    return new Promise((resolve, reject) => {
        let client = null;
        let clientObj = sessionStorage.getItem("Client_" + environment);
        if (clientObj) {
            client = JSON.parse(clientObj);
        }
        
        if (!client) {
            APIGet({
                "controller": "configuration",
                "action": "getclient",
                "environment": environment
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    client = result.client;
                    sessionStorage.setItem("Client_" + environment, JSON.stringify(client));
                    resolve(client);
                }
                else {
                    reject(result.errors);
                }
                //return result.authprovidertype;
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(client);
        }
    });
}

export function GetCurrentBrand(environment) {
    return new Promise((resolve, reject) => {
        let brand = null;
        let brandObj = sessionStorage.getItem("Brand_" + environment);
        if (brandObj) {
            brand = JSON.parse(brandObj);
        }

        if (!brand) {
            APIGet({
                "controller": "brand",
                "action": "getcurrentbrand",
                "environment": environment
            }).then((response) => {
                var result = response.data;
                if (result.success) {
                    brand = result.brand;
                    sessionStorage.setItem("Brand_" + environment, JSON.stringify(brand));
                    resolve(brand);
                }
                else {
                    reject(result.errors);
                }
                //return result.authprovidertype;
            }).catch((error) => {
                reject(error);
            });
        }
        else {
            resolve(brand);
        }
    });
}

//export { useConfiguration };
   