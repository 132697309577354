import React from 'react';
import './module.scss';
const Module = (props) => {
    console.log(props)
    const handleOnSubmit = (e) => {
     
        e.preventDefault();
       window.location.href = props?.url;
       
    }
    return <>
        <div className="modular container">
            <div className="item-container">
                <div className="item-wrapper">
                    <h1 className="module-heading">{props?.header}</h1>
                </div> 
                <div className="item-wrapper">
                    <p>{props?.description}</p> 
                </div>
                <div className="item-wrapper">
                    <button className="primary-button" onClick={handleOnSubmit}> {props?.button}</button>
                </div>
            </div>
        </div>
    </>
}

export default Module;