import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import "../../general.scss";
import { APIGet } from '../API/APIRequest'
import WhoIsItFor from '../ProductOffer/WhoIsItFor'
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';
import Button from '../UI/Buttons/Button';
import { SaveToOrder, UpdateBasket, GetBasket, Basket } from './Payment/Basket';
import { useAuth } from '../Authentication/AuthenticationProvider';
import { Loading } from '../Utilities/Loading';
import usePageTitle from '../../Hooks/usePageTitle';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import { getOriginatingUrl, getThankyouPageUrl } from '../Utilities/GetUrlFunctions';
import { gtmEventTriggers } from '../Utilities/GtmFunctions';
import { LogWebActivity } from '../Webactivity/LogWebActivity';
import { GetADToken } from '../Utilities/TokenManager';
import Module from '../UI/module/Module';
import Variants from '../ProductOffer/Variants/Variants';
import { extractAndStoreParams} from '../Utilities/Functions';

export function LandingPage(props) {

    const searchParams = new URLSearchParams(window.location.search);
    
    const [errors, setErrors] = useState([]);
    const [basketErrors, setBasketErrors] = useState([]);
    const [actions, setActions] = useState([]);
    const [basket, setBasket] = useState(null);
    const [loading, setLoading] =useState(true)
    const [recipient, setRecipient] = useState("");
    const [returnUrl, setReturnUrl] = useState("");
    const [adTokenExpired, setAdTokenExpired]= useState({status:false, message:["error.tokenexpired"] });
    const { fid } = useParams();
    const [showPopup, setShowPopup] = useState(true);
    const auth = useAuth();
    const { t, i18n } = useTranslation();
    const {setFid, metaData} = props;
    const [actionName, setActionName]= useState("");
    const navigate = useNavigate();
    const [isIncompatibleVariant, setIsIncompatibleVariant] = useState(false)
  
    //for discount flow
    const adToken = GetADToken();
    const discountApplied = searchParams.get("discountApplied");
    const prevBasketDataForDiscountFlow = sessionStorage.getItem("prevBasketDataForDiscountFlow")
    const purchaseTargetForDiscountFlow = sessionStorage.getItem("purchasingFor")
    

    const loadBasket = () => {
     let purchaseTargetFromSettings = "";
        if (!basket && !actions.includes("getbasket")) {
            for (const p of searchParams) {
        
            }
            actions.push("getbasket");
            let postCompleteUrl = searchParams.get("retURL");
          
            let props = {
                "fid": fid, "loadfromserver": true, "userToken": auth?.userData?.token
            }
            if (postCompleteUrl) {
                props = { "fid": fid, "loadfromserver": true, "userToken": auth?.userData?.token, "postCompleteUrl": postCompleteUrl };
            }
            GetBasket(props).then((result) => {
                
                //when directly being taken to payment screen skipping landing page then we need to set recipeint values
                if(result?.allowgifting && !result?.allowindividualcheckout){
                      purchaseTargetFromSettings="gift";
                }else if(!result?.allowgifting && result?.allowindividualcheckout){
                      purchaseTargetFromSettings="self";
                }

                checkBasketForAutoRedirect(result, purchaseTargetFromSettings);
                setBasket(result);  
                
                if(!discountApplied){
                    sessionStorage.setItem("originatingUrl", result?.originatingurl)
                }
              
    
            }).catch((basketerrors) => {
                setBasketErrors(basketerrors)
          
                setLoading(false);
               setReturnUrl(basketerrors?.originatingurl);
                 
            });
        }
    }

    const loadBasketOnSelection = (purchaseTarget) => {
        
            GetBasket({ "fid": fid, "loadfromserver": true, "userToken": auth?.userData?.token, "purchaseTarget": purchaseTarget }).then((result) => {
                 
                if(discountApplied){
                    sessionStorage.setItem("discountApplied", discountApplied)
                    let prevBasketData= JSON.parse(prevBasketDataForDiscountFlow)
                    checkBasketForAutoRedirectForDiscountFlow(prevBasketData, purchaseTarget)  //redirection for discount flow
                }
                else{
                    
                    checkBasketForAutoRedirect(result); //redirections in other flows
                }
               
                 setBasket(result);

                 if(!discountApplied){
                    sessionStorage.setItem("originatingUrl", result?.originatingurl)
                }
              
            }).catch((basketerrors) => {
                setBasketErrors(basketerrors)
              
                setReturnUrl(basketerrors?.originatingurl);
          
            });

       
    }

 
    const checkBasketForAutoRedirect = (basketData, purchaseTargetFromSettings) => {
        
        let purchasingFor = recipient || purchaseTargetFromSettings      //recipient comes from the choice made by the user  //purchaseTarget from the settings come from basket settings
        let redirectUrlString = "";
        let queryParamsString = sessionStorage.getItem("queryParamsString");
        const existingParams = queryParamsString ||  searchParams.toString() && searchParams || ""  //queryParams string from session || at times when straightaway going to the checkout--fetching searchParams directly from the url
        
        const newParam = `purchasingFor=${purchasingFor}`;
    
       // if basket has both gift & individual (self) options, drop through & let this page render
        if (basketData?.allowindividualcheckout && basketData?.allowgifting)
        {
            setLoading(false);
            return;
        }
        
        if (basketData?.allowguestcheckout && !basketData?.allowcustomercheckout) {
            
            redirectUrlString += "/guest/";
        }
        else {
           
            redirectUrlString += "/payment/";
        }

        redirectUrlString += fid;
     
        if(purchasingFor){
        // To check for existing query parameters
        if (existingParams) {
           
            redirectUrlString += "?" + existingParams + "&" + newParam;
        } else {
            
            redirectUrlString += "?" + newParam;
        }
        }
          window.location.href = redirectUrlString
    };
    
    //for discount flow
    const checkBasketForAutoRedirectForDiscountFlow = (basketData, purchaseTargetForDiscountFlow) => {

        let purchasingFor = purchaseTargetForDiscountFlow
        let redirectUrlString = "";
        let queryParamsString = sessionStorage.getItem("queryParamsString");
        const existingParams = queryParamsString || "";  //initial query params being retained from session
        const newParam = `purchasingFor=${purchasingFor}`;
      
        if ((basketData?.allowguestcheckout && !basketData?.allowcustomercheckout) || (discountApplied && adToken?.isGuest)) {
            redirectUrlString += "/guest/";
        }
        else {
             redirectUrlString += "/payment/";
        }
         redirectUrlString += fid;
        
        if(purchasingFor){

        // To check for existing query parameters
            //Skip - appending "purchasingFor" if it is already present in the existingParams
            if (existingParams && !existingParams.includes("purchasingFor")) {
                redirectUrlString += "?" + existingParams + "&" + newParam;
            }
            else if (existingParams) {
                redirectUrlString += "?" + existingParams;
            }
            else {
                redirectUrlString += '?' + newParam;
            }
        }
       
        window.location.href = redirectUrlString;

    };
    
    useEffect(()=>{

        let adTokenError = searchParams.get("error");
        if (adTokenError) {
            setLoading(false);
            setAdTokenExpired(adTokenExpired => ({...adTokenExpired, status: true}));

        } else {

       let tempFid =  sessionStorage.getItem("fid")
       if(!tempFid || fid !== tempFid){
        
        //as the new session starts, removal of session variables
        sessionStorage.removeItem("BeginEventTriggered");
        sessionStorage.removeItem("CheckoutStarted");
        sessionStorage.removeItem("CheckoutCompleted");
        sessionStorage.removeItem("CheckoutStartedFiredFor");
        sessionStorage.removeItem("stripeData");
       
            //deletion once out of the flow for a basket
        let queryParamsString = sessionStorage.getItem("queryParamsString");
        if(!discountApplied){
            sessionStorage.removeItem("sessionDeliveryDetails");
            sessionStorage.removeItem("discountApplied");
            if(queryParamsString){
                sessionStorage.removeItem("queryParamsString"); 
            }}
        }

            if (errors.length == 0) {

                let isStringPresent = sessionStorage.getItem("queryParamsString");

                if (!isStringPresent && !discountApplied) {
                    extractAndStoreParams(window.location.href);   //extracting query params on first mount on LP, first prop is url, second prop can be any query param that you do not want to be stored-refer the function
                }

                if (discountApplied && purchaseTargetForDiscountFlow) {
                    loadBasketOnSelection(purchaseTargetForDiscountFlow);
                    
                } else {
                    loadBasket();
                }

            }
    
    }

     }, [])

     useEffect(()=>{

        //begin_checkout event 
        let beginEventTriggered= sessionStorage.getItem("BeginEventTriggered");
       
        if (metaData?.basketmetadata && beginEventTriggered===null && (basket?.allowindividualcheckout && basket?.allowgifting)) {
            gtmEventTriggers("begin_checkout");
            sessionStorage.setItem("BeginEventTriggered", true)
             
            //web activity: checkout-started log to only be made if not fired before, to be fired once
            let checkoutStarted=sessionStorage.getItem("CheckoutStarted");

            if(checkoutStarted===null){
                let adtoken=GetADToken();

                //if user is logged in then only the log should be made, else no log
                if (adtoken) {
                   setActionName("Checkout started");
                   sessionStorage.setItem("CheckoutStartedFiredFor", adtoken?.username)

                //sessionStorage.setItem("CheckoutStarted", true);
            }
        }
        
     }}, [metaData?.basketmetadata])

    useEffect(()=>{
       
        setFid(fid);   //to pass to metadata extraction
      },[fid])


    const handleOnSubmit=()=>{
       
        //selection by user

        if(basket){
           loadBasketOnSelection( recipient || "unknown" )
        }

    };

    //expose basketErrors and checks if the chosen variants are incompatible
    useEffect(() => {   
        basketErrors?.messages?.map(item => {
            if (item === "error.basketguestonlyandnoguest" || item === "error.basketnogiftnoindividual") {

                setIsIncompatibleVariant(true);
            } else {
                setIsIncompatibleVariant(false);
            }

        })
    }, [basketErrors])
 
    //if there is an incompatible variants the change order button goes to url
     
    usePageTitle(`Checkout - choose order type -  ${props.brandingElement?.id}`);

  

   return ( !loading ? 
          
       <>
       
           <div className="container-wrapper one-column">
            <>
           {adTokenExpired.status ? <GenericErrorBox errors={adTokenExpired.message} url={getOriginatingUrl(basket, returnUrl)}/>   :
           <>
               {isIncompatibleVariant ? <Module header={t("module.incompatiblevariants.header")}
                   description={t("module.incompatiblevariants.description")}
                   button={t("module.incompatiblevariants.action")}
                   url={returnUrl}
               /> :
                   <GenericErrorBox errors={basketErrors} url={getOriginatingUrl(basket, returnUrl)} thankyouPageUrl={getThankyouPageUrl()} />}
               {basket &&
                <div className="container">
                  <div className="item-container">
                     <div className="whoisitfor-block">
                        <WhoIsItFor recipient={recipient}  handleOnSubmit={handleOnSubmit} setRecipient={setRecipient} basket={basket} showPopup={showPopup} setShowPopup={setShowPopup} />
                        </div> 
                      </div>
                    </div>
                    }
                    </>}
                    </>
                    
                </div>

                {/*Checkout started log needs actionName */}
                <LogWebActivity actionName={actionName}/> 
                
               </>
               : <Loading loading={loading}/>  )
}
