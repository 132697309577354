import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n'

export function Acknowledgement(props) {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <h2>{t('acknowledgement.title')}</h2>
        </div>
    )
}