import { GetConfiguration } from '../Configuration/ConfigurationManager'
import { APIGet, APIPost, getEnvironment } from '../API/APIRequest';

export async function SaveSessionCookie(cookieName, cookievalue) {
    return new Promise((resolve, reject) => {
        resolve(true)
    });
};

export function AuthenticateWithServerCookie() {
    return new Promise(async(resolve, reject) => {
        var environment = getEnvironment();
        try {
        let response = await  APIPost({
            "controller": "auth",
            "action": "authenticatewithcookie",
            "environment": environment
        });
      
        var result = response.data;
            if (result.success) {
                resolve(result.user);
                
            }
            else {
                reject(result.errors);
            }
        } catch (error) {
            reject(error);
        };
        
      });
    
}
export function DeleteCookie(name, domain) {
    return new Promise((resolve, reject) => {
        var environment = getEnvironment();
        APIPost({
            "controller": "auth",
            "action": "deletecookie",
            "environment": environment
        })
            .then((response) => {
                var result = response.data;
                if (result.success) {
                    resolve(result.user);
                }
                else {
                    reject(result.errors);
                }
            }).
            catch((error) => {
                reject(error);
            });

    });  
};

