import { Component } from "react";
import { APIPost,getEnvironment } from '../API/APIRequest'


    //constructor(props) {
    //    super(props);
    //    this.getToken = this.getToken.bind(this);
    //    this.saveToken = this.saveToken.bind(this);
    //    this.refreshToken = this.refreshToken.bind(this);
    //}

export function GetADToken() {
    var tokenString = sessionStorage.getItem('adtoken');
    if (tokenString) {
        var userToken = JSON.parse(tokenString);
        return userToken;
    }
    return null;
}



export async function RefreshADToken() {
    try {
        const userToken = GetADToken();
        if (!userToken) {
            return null;  // No token to refresh
        }

        const bodyFormData = new FormData();
        bodyFormData.append('adToken', userToken.token);

        const environment = getEnvironment();
        const response = await APIPost({
            controller: "auth",
            action: "validateandrefreshadtoken",
            environment: environment,
            data: bodyFormData
        });

        const result = response.data;

        if (result.success) {
            userToken.token = result.token;
            SaveADToken(userToken);
            return userToken;  // when token gest refreshed
        } else {
            RemoveADToken();
            return null;  // when token refreshal fails
        }
    } catch (error) {
        console.error("Failed to refresh AD token:", error);
        RemoveADToken();
        return null;  // if the call fails
    }
}


export function SaveADToken(userToken) {
    sessionStorage.setItem('adtoken', JSON.stringify(userToken));
}

// export function SaveGuestToken(userToken) {
//     sessionStorage.setItem('guesttoken', JSON.stringify(userToken));
// }

export function RemoveADToken() {
    sessionStorage.removeItem('adtoken');
}

export function GetADRefTokenLocal() {
    var tokenString = localStorage.getItem('auth0token');
    var auth0Token = null;
    if (tokenString) {
        auth0Token = JSON.parse(tokenString);
    }
    return auth0Token;
}

export function SaveADRefToken(auth0Token) {
    localStorage.setItem('auth0token', JSON.stringify(auth0Token));
}

export function RemoveADRefToken() {
    localStorage.removeItem('auth0token');
}