import { useState, useEffect } from 'react';

const useDateLocalisation = (dateString, options = {}) => {
    const [localisedDate, setLocalisedDate] = useState('');

    useEffect(() => {
        if (dateString) {
            const dateObject = parseDateString(dateString);
            if (dateObject && isValidDate(dateObject)) {
                const formattedDate = formatDate(dateObject, options);
                setLocalisedDate(formattedDate || 'Invalid Date');
            } else {
                setLocalisedDate('Invalid Date');
            }
        }
    }, [dateString, options]);

    const parseDateString = (str) => {
        const parts = str.split(' ');
        const day = parseInt(parts[0], 10);
        const monthName = parts[1];
        const year = parseInt(parts[2], 10);
        const timeParts = parts[3].split(':');
        const hour = parseInt(timeParts[0], 10);
        const minute = parseInt(timeParts[1], 10);

        const monthIndex = new Date(Date.parse(monthName + " 1, 2000")).getMonth();

        return new Date(Date.UTC(year, monthIndex, day, hour, minute));
    };

    const isValidDate = (d) => d instanceof Date && !isNaN(d.getTime());

    const formatDate = (date, options) => {
        const {
            year = 'numeric',
            month = 'long',
            day = 'numeric',
            hour = 'numeric',
            minute = 'numeric',
            timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone, // Set to the desired time zone
            locale = navigator.language || 'en-GB',
        } = options;

        const dateMonthOptions = { year, month, timeZone };
        const dateDayOptions = { day, timeZone };
        const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: false, timeZone };

        const formattedDay = date.toLocaleString(locale, dateDayOptions);

        const formattedMonth = date.toLocaleString(locale, dateMonthOptions);

        const formattedTime = date.toLocaleString(locale, timeOptions);

        // Concatenate date and time
        return `${formattedDay}  ${formattedMonth} ${formattedTime}`;
    };

    return localisedDate;
};

export default useDateLocalisation;