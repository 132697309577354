 
import React, { Component, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import './WarningComponent.css'
import { Link } from 'react-router-dom'
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import { useAuth } from '../AuthenticationProvider'
import useCreateUrl from '../../../Hooks/useCreateUrl';
import useBodyClass from '../../../Hooks/useBodyClass';
import usePageTitle from '../../../Hooks/usePageTitle';


export function WarningComponent(props) {
    const { t, i18n } = useTranslation();
    const createUrl = useCreateUrl();
    const auth = useAuth();

    useBodyClass(['disabled-account', props.brandingElement?.bodyClass]);
    usePageTitle(`This account is disabled - ${props.brandingElement?.id}`);
    const htmlString = props.brandingElement?.disabledUserErrorText;
  
    //The use of dangerouslySetInnerHTML is replacing the innerHTML
    //if you need further information please read this https://reactjs.org/docs/dom-elements.html
    return (
        <div className="container" aria-live="polite">
           <div className="item-container">
                <Link to={createUrl("login")} className="back-button">
                    <span className="back-icon"> </span>
                    <span>{t('forgottenpassword.backlinktext')}</span>
                </Link>
            </div>
            <div className="item-container">
                <div className="item-wrapper">
                    <div className="generic-error-box warning">
                        <span className="generic-error-icon"></span>
                        <span>
                            {t('error.accountinactive')}
                        </span>
                    </div> 
                </div>
            </div>
            <div className="item-container">
                <div className="item-wrapper" dangerouslySetInnerHTML={{ __html: htmlString }}>
          
                </div> 
            </div>
            
        </div>
    )
}

 