import { Fragment, useState, forwardRef } from "react";
import InputNew from "./Input.js";
import ErrorBox from '../ErrorBox/ErrorBox';
import './InputRe.css';
import { Link } from "react-router-dom";
import Checkbox from "../Checkbox/Checkbox.js";
//import { shouldProcessLinkClick } from "react-router-dom/dist/dom";
const InputWrapperRefacture = forwardRef((props, ref) => {

    const { description, edit, labelText, inputSize, text, label,  btnText, optionalField, setButtonEvent, helpField, inputFieldType, route, value, onClickFunction } = props;


    const [showHelp, setShowHelp] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [editable, setEditable] = useState(false);
    const [inputType, setInputType] = useState();


    //basic text input with a label along with a standard subtext with an exception of email input box

    let inputWidth = () => {
        switch (inputSize) {
            case 'full-width':
                return "full-width"
            case 'large':
                return "large"
            case 'medium':
                return "medium"
            case 'small':
                return "small"
            default:
                return "large"
        }
    }

    let labelWidth = () => {
        switch (inputSize) {
            case 'full-width':
                return "full-width"
            case 'large':
                return "large"
            case 'medium':
                return "medium"
            case 'small':
                return "small"
            default:
                return "large"
        }
    }

    const helpLinkHandler = (e) => {
        e.preventDefault();
        return setShowHelp(!showHelp);
    }

    // props.disabled==="true" ? "" :

    const inputField = () => {
        return <div class="field-wrapper">

            <div className={`input-wrapper div-wrapper ${inputWidth()}  ${props.invalidClass ? props.invalidClass : (value?.length > 0) ? "filled" : ""}`}>
                <input className={`${inputWidth()}`} ref={ref} type="text" disabled={edit ? !editable : false} aria-label={label} label={label} {...props} />
                {route ? <Link to={route}>{btnText}</Link> : (edit || onClickFunction) ? <button type="button" onClick={(e) => { edit ? setEditable(true) : onClickFunction(e.target.value)}}>{btnText}</button> : ""}
            </div>
        </div>
    }

    const dateField = () => {
        return <div class="field-wrapper">

            <div className={`input-wrapper div-wrapper ${inputWidth()}  ${props.invalidClass ? props.invalidClass : (value?.length > 0) ? "filled" : ""}`}>
                <input className={`${inputWidth()}`} ref={ref} type="date"  aria-label={label} label={label} {...props} />
              
            </div>
        </div>
    }

    const inputText = () => {
        return <div class="inputText-wrapper">
            {text}
        </div>
    }

    const checkboxField = () => {
        return <div className="field-container">
            <div class="field-wrapper">
                <input className={inputWidth()} type="checkbox" label={label}  {...props} />
            </div>
            {text && inputText()}
        </div>
    }

    const radioField = () => {
        return <div class="field-wrapper">
               <label className="radio-label" htmlFor={props.id}>
        
    <input className={inputWidth()} type="radio" label={label}  {...props} />
        
        <span class="inputText-wrapper">{labelText ? labelText : label}</span>
        
        {text && inputText()}
        
        </label>
        
        </div>
    }

    //div-wrapper is the class enclosing the input field and the button

    const passwordField = () => {
        return <div class="field-wrapper">

<div className={`input-wrapper div-wrapper ${props.invalidClass ? props.invalidClass : (value?.length > 0) ? "filled" : ""}`}>
                <input ref={ref} className={`${inputSize}`} no-outline label={label} type={showPassword ? "text" : "password"} {...props} inputType={showPassword ? "text" : "password"} />
                <button type='button' name="show-hide" onMouseDown={(e) => { setButtonEvent(true) }} onClick={(e) => {
                    setShowPassword(!showPassword);
                }}>{showPassword ? "Hide" : "Show"}</button>
            </div>

        </div>
    }


    const labelField = () => {
        return <div class={`label-wrapper ${props.labelSize ? props.labelSize : (value?.length > 0) ? "filled" : ""}`}>
            <div class="label-text">
                <label className="label" htmlFor={props.id}> {props.label} </label>
                {description ? <p className="label-description">{description}</p> :""}
                {/*not present in code this particular spec */}   {(props.specs === "search_input") && <p className="meta">Type first letters to find..</p>}
            </div>
            <div class="label-aid">
                {optionalField ? <div className="required-text">Optional </div> : ""}
                {helpField ? <div className="help-text"> <a href="#" className="help-text-btn" onClick={helpLinkHandler}> | <span className={!showHelp ? "question-icon" : "close-icon"} ></span> </a> </div> : ""}

            </div>
        </div>
    }

  const textBox=()=>{
    return <div class="field-wrapper">
              <div className={`input-wrapper div-wrapper ${inputWidth()}  ${props.invalidClass ? props.invalidClass : (value?.length > 0) ? "filled" : ""}`}>
                  <textarea className={`${inputWidth()}`} rows="4"ref={ref} type="date"  aria-label={label} label={label} {...props} />
                 
              </div>
           </div>

  }

    return <>
        {(!label && !description && !optionalField && !helpField) ? !labelField() : labelField()}
        {inputFieldType === 'password' && passwordField()}
        {inputFieldType === 'checkbox' && <Checkbox label={labelText} checked={!props.checked} onChange={props.onChange} />}
        {inputFieldType === 'radio' && radioField()}
        {inputFieldType === 'text'  && inputField()}
        {inputFieldType  === 'date'  && dateField()}
        {inputFieldType  === 'textbox'  && textBox()}


    </>


})
export default InputWrapperRefacture;