import { useParams, Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import { APIUrl, APIWithEnvironmentUrl, getEnvironment } from '../../Utilities/APIUrl';
import FooterBranding from "./FooterBranding";

const AbacusFooter = props => {
     

    return (
            <footer className="footer">
                <div className="footer-container">
                    <div className="sleeve">
                    <FooterBranding brandingFooter={props.brandFooter}> </FooterBranding>
                    </div> 
                 </div>
            </footer>
    )
}


export default AbacusFooter;