const UpdatingButton = () => {
   return (

      
      <svg width="24" style={{marginBottom:"-6px"}} className="updating-icon" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2" className="updating-icon-circle" d="M22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5C9.21523 22.5 6.54451 21.3938 4.57538 19.4246C2.60625 17.4555 1.5 14.7848 1.5 12C1.5 9.21522 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12L22.5 12Z" stroke="white" stroke-width="3"/>
      <path className="updating-icon-loader" d="M6.74973 21.0935C5.55558 20.4041 4.50893 19.4862 3.66952 18.3923C2.83011 17.2983 2.21439 16.0498 1.85751 14.7179C1.50063 13.386 1.40958 11.9968 1.58956 10.6297C1.76954 9.26267 2.21702 7.94442 2.90646 6.75027C3.5959 5.55613 4.51379 4.50947 5.60773 3.67006C6.70167 2.83065 7.95023 2.21493 9.28213 1.85805C10.614 1.50117 12.0032 1.41012 13.3703 1.5901C14.7373 1.77008 16.0556 2.21757 17.2497 2.90701" stroke="white" stroke-width="3" stroke-linecap="round"/>
      </svg>
      
   )
}
export default UpdatingButton;