import { useTranslation } from 'react-i18next';
import '../../i18n'
export function Loading(props) {
    //const { t, i18n } = useTranslation();
    if (!props.loading) {
        return ('')
    }
    //return (<img src='/assets/misc/loading_2.gif' alt={t('global.loading')} height="100px" width="100px"/>);
    return (
        <div className={`${props.class} loader-wrapper`}>
            <div className="loader"></div>
        </div>
      );
}