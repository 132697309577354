import { react, useState } from "react";
import "./OptionCards.scss";
import ReactHtmlParser from 'html-react-parser';


export default function OptionCards() {
  const [optionsArray, setOptionsArray] = useState([{ heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription...we've got great offers?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },
  { heading: "Want to buy a gift subscription?", description: "Click here to find out the options for a gift subscription." },

  ])

  const mapFunction = (array) => {
    return array.map((item) => {
      return (<div className="card">
                 <div className="item-wrapper">
                    <h5>{item.heading}<span className="right-arrow"></span></h5>
                    <div className="option-description">
                        <p>{item.description}</p>
                    </div>
                 </div>
             </div>
            )
         })
       }

  const optionCardsUi = () => {
    let temp = [...optionsArray];
    let arrLength = temp.length;
    const maxWidth = window.matchMedia("(max-width=1025px)")
    if (arrLength >= 7 && (arrLength % 3) === 1 && maxWidth.matches == false) {
      let arrTemp = temp.splice(arrLength - 4, 4)
      return <div className="spliced-container">
                <div className="options-card-container wrap justify-space-between">
                    {mapFunction(temp)}
                </div>
                <div className="options-card-container justify-space-between">
                    {mapFunction(arrTemp)}
                </div>
            </div>
    }
   else {
      return <div className={`options-card-container justify-center ${optionsArray.length > 9 ? "wrap" : ""}`}>
                {mapFunction(temp)}
             </div>
    }
  }
  return (<div className="options-container">
              <div className="item-container">
                 <h3>Other Subscription Options</h3>
              </div>
              {optionCardsUi()}
          </div>)
        }