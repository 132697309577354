import { t } from "i18next";
import { recursive } from "merge";
import { react, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "../../general.scss"
import Button from "../UI/Buttons/Button";
import ErrorBox from "../UI/ErrorBox/ErrorBox";
import InputWrapperRefacture from "../UI/Inputs/InputWrapperRefacture";
import "./ProductOffer.scss"
import useFindHeight from '../../Hooks/useFindHeight';
import "./whoisitfor.scss";
export default function WhoIsItFor(props) {

    const { basket, setShowPopup, recipient, setRecipient, handleOnSubmit } = props;
    const [IsLoading, setIsLoading] = useState(false)
    const [observedDiv, elementHeight] = useFindHeight();
    const [IsBlank, setIsBlank] = useState(false);
    const navigate = useNavigate();

    const handleOnClick = (e, value) => {
     
        const option = e.target.querySelector(`#radio${value}`)
      
        if (option) {
            setRecipient(value);
            option.focus();
            option.click();
        }
    }

    const handleOnChange=(value)=>{
        setRecipient(value);  
        // setIsBlank(false);
        // sessionStorage.setItem("purchasingFor", value)
   
    }

    const ContinueToCheckout=(e)=>{
    
       e.preventDefault();
       setShowPopup(false);
       if(recipient){
         setIsBlank(false)
         setIsLoading(true);
         handleOnSubmit(e);   }
       else{
         setIsBlank(true)
       }
     
        // if(recipient==="myself"){
        // let url = '/payment/' + basket.id + "?purchasingFor="+recipient 
        // navigate(url);
        // }
        // //we need more flags for guest and gift
        // if(recipient==="someoneelse"){
        //     let url = '/guest/' + basket.id + "?purchasingFor="+recipient 
        //     navigate(url);
        // }
        
       
    }

    return (
    // <div className="recipientSelectionBlock" >
        // <div className="container">
      <>
     
            <div className="item-container">
                <div className="item-wrapper">
                    <h1 className="module-heading">{t('checkout.giftBuyingOptionTitle')}</h1>
                  
                </div>
            </div>
            <div className="item-container">
                <div ref={observedDiv} className={`item-wrapper ${elementHeight > 135 && "customScroll customScrollerHeight"}`}>

                {basket.lines?.map((line, index) => {
                  
                    return <> 
                          <div className="module-row">
                                <div className="item-wrapper">  
                                    <div class="two-columns-row">
                                        <div class="module-column">
                                            <div class="module-block">
                                                <h3 class="module-subheading"> <strong>{line.productvariantdisplayname ? line.productvariantdisplayname : line.productvariantname}</strong> </h3>
                                            {line.quantity > 1 && <span class="module-quantity-block">  x {line.quantity}</span>}
                                            </div>
                                            <p class="module-description"> </p>
                                        </div>
                                    <div class="module-price"> {basket.currencysymbol}{line.linetotalnet?.toFixed(2)}</div>
                                    </div> 
                                </div>
                           </div>  
                       
                        <div className="item-wrapper">
                            <div className="border-seperator"> </div>
                        </div>
                      
                    </>
                })}
                </div>
            </div>  
            <div className="item-container"> 
                <div className="item-wrapper">
                    <strong> {t('checkout.giftBuyingOptionChoiceLabel') } </strong> 
                </div>
                </div>
               
               <form onSubmit={ContinueToCheckout} >
                <div className="item-container">
                <div className="item-wrapper">
                <div onClick={(e) => { handleOnClick(e, "myself") }} className="">
                    <div className={`div-wrapper ${(recipient==="self") ? "filled" :""}  ${ (IsBlank) && "Invalid-input"}`}>
                        <InputWrapperRefacture
                            labelText={t('checkout.giftBuyingChoiceLabelSelf')}
                            inputSize="full-width"
                            inputFieldType="radio"
                            id={"radio" + "self"}
                            name="recipient"
                            role="radio"
                            tabIndex="0"
                            value="self"
                            onChange={(e) => { handleOnChange("self")}}
                        />

                    </div>
                </div>
                <div onClick={(e) => { handleOnClick(e, "gift"); }} className="">
                    <div className={`div-wrapper  ${(recipient==="gift") ? "filled" :  ""}  ${ (IsBlank) && "Invalid-input"}`}>
                            <InputWrapperRefacture
                                labelText={t('checkout.giftBuyingChoiceLabelGift')}
                                inputSize="full-width"
                                inputFieldType="radio"
                                id={"radio" + "gift"}
                                name="recipient"
                                role="radio"
                                tabIndex="0"
                                value="gift"
                                onChange={(e) => { handleOnChange("gift") }}
                            />

                        </div>
                    </div>
                </div>
                    {IsBlank && <span role="alert" id="who-errors"><ErrorBox>{t('whoisitfor.blankerrortext')}</ErrorBox> </span>}
                </div>
                    <div className="item-container"> 
                    <div className="item-wrapper">
                           {!IsLoading ?
                            <Button type="button" buttonSize="full-width">{t('whoisitfor.continuebuttontext')}</Button>
                            : IsLoading ?
                            <Button type="button" buttonSize="full-width" hasIcon="updating" isDisabled="true">{t('whoisitfor.continuebuttontext')}</Button>
                            : ""}
                        </div>
                    </div>
              
                </form>
    
        </> 
    )
}