const ButtonSvg = () => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12">
      <g id="Icon" transform="translate(-241.24 -330.38)">
        <g id="Group_2325" data-name="Group 2325" transform="translate(241.24 330.38)">
          <path id="Path_2380" data-name="Path 2380" d="M242.241,342.38a1,1,0,0,1-.707-1.707l4.295-4.292-4.295-4.294a1,1,0,0,1,1.414-1.414l5,5a1,1,0,0,1,0,1.414l-5,5A.99.99,0,0,1,242.241,342.38Z" transform="translate(-241.241 -330.38)" fill="#fff"/>
        </g>
      </g>
    </svg>
     
   )
}
export default ButtonSvg;