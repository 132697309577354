import { useState, useEffect, useRef } from 'react';

function useFindHeight() {
    const [height, setHeight] = useState(0);
    const observedDiv = useRef(null);

    useEffect(() => {
        if (!observedDiv.current) {
            // we do not initialize the observer unless the ref has
            // been assigned
            return;
        }

        // we also instantiate the resizeObserver and we pass
        // the event handler to the constructor
        const resizeObserver = new ResizeObserver(() => {
            if (observedDiv?.current?.offsetHeight !== height) {
                setHeight(observedDiv?.current?.offsetHeight);
            }
        });

        // the code in useEffect will be executed when the component
        // has mounted, so we are certain observedDiv.current will contain
        // the div we want to observe
        resizeObserver.observe(observedDiv.current);


        // if useEffect returns a function, it is called right before the
        // component unmounts, so it is the right place to stop observing
        // the div
        return function cleanup() {
            resizeObserver.disconnect();
        }
    },
        // only update the effect if the ref element changed
        [observedDiv.current])


    return [observedDiv, height];
}

export default useFindHeight;