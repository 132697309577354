import { useTranslation } from 'react-i18next';
import '../../i18n'
export function ErrorBox(props) {
   
    const { t, i18n } = useTranslation();
        if (!props.errors || props.errors.length == 0) {
            return ('');
        }

    const errorList = [];
    props.errors.map((error) => { errorList.push(<li key={errorList.length}>{t(error)}</li>) });
    return (<div className="errorBox">
        <p>{t('errorbox.title')}</p>
        <ul>
            {errorList}
        </ul>
    </div>
    // <ErrorBoxCS>{t('login.combinationdoesnotmatch')}</ErrorBoxCS>
    );
}

export function ProcessErrors(errors) {


    const errorList = [];

    const ProcessError = (error) => {
        switch (error) {
            case 'too_many_attempts':
                errorList.push('error.toomanyattempts');
                break;
            case 'invalid_grant':
                errorList.push('error.authenticationfailed');
                break;
            default:
                if (error.startsWith("error.")) {
                    errorList.push(error);
                }
                else {
                    errorList.push("error." + error);
                }
                break;
        }
    }
        
    if (errors.constructor === Array) {
        for (let i = 0; i < errors.length; i++) {
            ProcessError(errors[i]);
        }
    }
    //if (typeof errors === 'string') {
    //    errorList.push(errors);
    //}
    else if (errors?.code)
    {
        ProcessError(errors.code);
    }
    else if (errors) {
        ProcessError(errors);
    }
    return errorList;
}