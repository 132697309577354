import { APIPost } from "../API/APIRequest";
export { loqateRequestLogs, splitName, appendClientQueryParams, extractAndStoreParams};


const loqateRequestLogs = (Id, response, type) => {

    let basketId=sessionStorage.getItem("fid");
    let brand=sessionStorage.getItem("Brand_null")
    let brandData= JSON.parse(brand);
    
    const data = new FormData();
            data.append("sessionid", Id);   //id for successful loqate request
            data.append("request", JSON.stringify(response));  //data for successful loqate request
            data.append("requesttype", type); 
            data.append("brandid", brandData.brandid); 
            data.append("basketid", basketId); 
            

         APIPost({
                "controller": "loqate",
                "action": "logloqaterequest",
                "environment":null,
                 data: data
               }).then((response)=>{
                if(response.status===200){
                    console.log("Log for loqate request created successfully") 
                }
                }).catch((error)=>{
                console.log("error in creating loqate log")
               })

}


 const splitName= (fullName)=> {
   
     try {

         if (typeof fullName !== 'string') {
             throw new Error("Input must be a string");
         }

         let trimmedName = fullName?.trim();
         let nameParts = trimmedName?.split(/\s+/);


         if (nameParts && nameParts.length > 0) {

             let firstName = nameParts[0];

             //everything after first name is going to be last name
             let lastName = nameParts.slice(1).join(' ');
             return [firstName, lastName];

         } else {

             return ["", ""];

         }
     } catch (e) {
         console.log("Error in processing the name input");
         return ["", ""];
     }
}

function appendClientQueryParams(url) {

    const queryParamsString = sessionStorage.getItem("queryParamsString");

    // Updating the URL with the initial query parans

    if (queryParamsString) {
        let newUrl = url + "?" + queryParamsString;
        return newUrl;

    } else {
        return url;
    }
}

const extractAndStoreParams = (url, excludeParams = []) => {
    const urlObject = new URL(url);
    const params = new URLSearchParams();

    // Extracting the intial query params
    const existingParams = urlObject.searchParams;

    existingParams.forEach((value, key) => {
        if (!excludeParams.includes(key)) {
            params.set(key, decodeURIComponent(value));
        }
    });
    
    let string = params.toString();

    if(string){

    // Storing the formatted query string in session
    sessionStorage.setItem('queryParamsString',  string);
    
    }
  
};
