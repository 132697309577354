import React, { Fragment, useState } from 'react';



export function Summary() {
    return (
        <>  
            <div className="item-container">
                <div className="item-wrapper" >
                    <a className="back-button" href="#">
                        <span class="back-icon"> </span><span>Back to Order</span></a>
                </div>
                <div className="item-wrapper">
                    <h2 className="module-heading">Order Summary</h2>
                  </div>
                <div className="item-wrapper">
                    <div className="item-field">
                        <h3 className="module-subheading"> Product title </h3>
                        <p className="module-description"> Product description </p>
                    </div>
                </div> 
                <div className="item-wrapper"> <div className="border-seperator"> </div> </div>
                <div className="item-wrapper">
                    <div className="two-columns-row"> <span>Sub total</span>  <span className="order-price"> 11.99</span></div>
                    <div className="two-columns-row"> <span>Tax</span>  <span className="order-price"> 2.99</span></div>
                    <div className="border-seperator"> </div>
                    <div className="two-columns-row"> <span className="order-price"><strong> Order total</strong></span>  <span className="order-price"> <strong>14.98</strong></span></div> 
                      </div> 
                </div> 
                <div className="block-container">
                <p> You are shopping in United Kingdom</p>
                <a href="#"> Shopping in the wrong region? </a>
            </div>

        </>
  );
}