import React, { useEffect } from 'react';

const usePageTitle = title => {
    const documentDefined = typeof document !== 'undefined';
    const originalTitle = React.useRef(documentDefined ? document.title : null);

        useEffect(() => {
            if (!documentDefined) return;

            if (document.title !== title) document.title = title;

            return () => {
                document.title = originalTitle?.current;
            };
    }, [title]);
};

export default usePageTitle;