import React, { forwardRef, useState, useImperativeHandle, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '../../Utilities/Loading'
import { useTranslation } from 'react-i18next';
import { APIPost } from '../../API/APIRequest'
import '../../../i18n';
import useBodyClass from  '../../../Hooks/useBodyClass';
import { gtmEventTriggers } from '../../Utilities/GtmFunctions';
import usePageTitle from '../../../Hooks/usePageTitle';
import { appendClientQueryParams } from '../../Utilities/Functions';

export function StripeResponseHandler(props) {
    
    const [errors, setErrors] = useState([]);
    const { t, i18n } = useTranslation();
    const { fid } = useParams();
    const [currentAction, setCurrentAction] = useState([]);
    const [loading, setLoading] = useState(true);
    useBodyClass(['payment-process']);
    usePageTitle(`Payment Processing with stripe -  ${props.brandingElement?.id}`);
    var params = (new URL(document.location)).searchParams;
    var setupIntentId= params.get("setup_intent");
    var paymentIntentId = params.get("payment_intent");
    var notificationredirect = params.get("notificationredirect");
     
   const ProcessStripePayment = () => {
       if (currentAction.length == 0) {
           if (setupIntentId) {
               currentAction.push("ProcessingFTSetup");
               APIPost({
                   "controller": "freetrial",
                   "action": "completetokenisedfreetrialsetup",
                   "data": {
                       "fid": fid
                   },
                   "environment": null,
                   "identifier": null
               }).then((ftresponse) => {
                   console.log(ftresponse);
                   let url= window.location.protocol + "//" + window.location.host + "/thankyou/" + ftresponse.data.OrderId ;
                   let updatedUrl= appendClientQueryParams(url);
                   window.location.href = updatedUrl;
                   
               }).catch((fterror) => {
                   if (fterror.response.data.idempotencyInfo && fterror.response.data.idempotencyInfo.source && fterror.response.data.idempotencyInfo.source === "StripeEvent") {
                       if (fterror.response.data.idempotencyInfo.status && fterror.response.data.idempotencyInfo.status === "S") {
                           let url = window.location.protocol + "//" + window.location.host + "/thankyou/" + fterror.response.data.OrderId
                           let updatedUrl = appendClientQueryParams(url);
                           window.location.href = updatedUrl;
                       } else {
                           if (fterror.response.data.idempotencyInfo.status && fterror.response.data.idempotencyInfo.status === "P") {
                               // Get the current URL and its search parameters
                               let currentUrl = new URL(window.location.href);
                               let params = currentUrl.searchParams;

                               // Get the current value of 'refreshtime', default to 1 if not set
                               let refreshTime = parseInt(params.get('refreshtime')) || 1;

                               // Increment the 'refreshtime' value
                               params.set('refreshtime', refreshTime + 1);

                               // Update the URL with the modified parameters
                               currentUrl.search = params.toString();

                               // Reload the page with the updated URL. user replace so that no new entry is added to history
                               //window.location.href = currentUrl.toString();
                               window.location.replace(currentUrl.toString());
                           }
                       }
                   }
               });
           }
           else if (paymentIntentId) {
                currentAction.push("ProcessingPayment");
                APIPost({
                    "controller": "stripe",
                    "action": "processpayment",
                    "data":
                    {
                        "fid": fid,
                        "paymentIntentId": paymentIntentId,
                        "source": "UserClient",
                        "preferredPaymentSource": params.get('preferredPaymentSource') || "UserClient"
                    }
                }).then((response) => {
                 
                    let url=  window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId 
                    let updatedUrl= appendClientQueryParams(url);
                    window.location.href = updatedUrl;
                    
            
                }).catch((error) => {
                    if (error.response.data.idempotencyInfo && error.response.data.idempotencyInfo.source && error.response.data.idempotencyInfo.source === "StripeEvent") {
                        if (error.response.data.idempotencyInfo.status && error.response.data.idempotencyInfo.status === "S") {
                            let url = window.location.protocol + "//" + window.location.host + "/thankyou/" + error.response.data.OrderId
                            let updatedUrl = appendClientQueryParams(url);
                            window.location.href = updatedUrl;
                        } else {
                            if (error.response.data.idempotencyInfo.status && error.response.data.idempotencyInfo.status === "P") {
                                // Get the current URL and its search parameters
                                let currentUrl = new URL(window.location.href);
                                let params = currentUrl.searchParams;

                                // Get the current value of 'refreshtime', default to 1 if not set
                                let refreshTime = parseInt(params.get('refreshtime')) || 1;

                                // Increment the 'refreshtime' value
                                params.set('refreshtime', refreshTime + 1);

                                // Update the URL with the modified parameters
                                currentUrl.search = params.toString();

                                // Reload the page with the updated URL. user replace so that no new entry is added to history
                                //window.location.href = currentUrl.toString();
                                window.location.replace(currentUrl.toString());
                            }
                        }
                    }
                })
           }
           else if (notificationredirect) {
               let oid = params.get("orderid");
               let url = window.location.protocol + "//" + window.location.host + "/thankyou/" + oid;
               let updatedUrl = appendClientQueryParams(url);
               window.location.href = updatedUrl;
           }
       }  
    }


    ProcessStripePayment();

    useEffect(()=>{
        props.setFid(fid);   //to pass to metadata extraction
      },[fid]) 

    return (
                <div className="container" aria-live="polite">
                        <div className="item-container">
                            <div className="item-wrapper">
                    <h1 className="module-heading" tabIndex="-1" >{t('stripeHandling.title')} </h1>
                            </div>
                        </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                    <p>{t('stripeHandling.message')} </p>
                            </div>
                        </div> 
                        <div className="item-container">
                            <div className="item-wrapper">
                                 <Loading loading={loading} />
                            </div>
                        </div>
                </div> 
             
    )
}

