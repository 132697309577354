import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext, useRef, forwardRef, useImperativeHandle } from 'react';
import '../../../i18n'
import { APIGet, APIPost } from '../../API/APIRequest';
import { ProcessErrors } from '../../Utilities/ErrorBox';
import { PaymentFormContext, formState } from '../PaymentFormReducer';
import ReactHtmlParser from 'html-react-parser';
import {  gtmEventTriggers } from '../../Utilities/GtmFunctions';
import { appendClientQueryParams } from '../../Utilities/Functions';


export const UpdateInvoicePaymentRequest = (props) => {
    return new Promise((resolve, reject) => {
        APIPost({
            "controller": "Invoice",
            "action": "CompleteInvoiceSetup",
            "environment": null,
            "data": {
                fid: props.basket.id
            }
        }).then((response) => {
            resolve(response);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const Invoice = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const fid = props.basket.id;
    const [ errors, setErrors] = useState([]);
    const [ invoiceText, setInvoiceText] = useState("");
    const [ actions, setActions] = useState([]);
    const { setErrorStates, errorStates, showError } = props;

    useImperativeHandle(ref, () => ({
        confirmPayment(event) {
            APIGet({
                "controller": "Basket",
                "action": "GetOrderId",
                "environment": null, 
                "identifier": fid
            }).then((response) => {
                
                gtmEventTriggers("add_payment_info")
                let url= window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId ;
                
                let updatedUrl= appendClientQueryParams(url);
                window.location.href = updatedUrl;
            
            }).catch((error) => {
                throw (error);
            });
        },
        errors
    }));

    const InvoicePaymentText = () => {
        return new Promise((resolve, reject) => {
            APIGet({
                "controller": "textasset",
                "action": "getsitetextassetbyname",
                "environment": null,
                "data": {
                    "name": "paymentByInvoice"
                }
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });

    };

    useEffect(() => {
        if (invoiceText === "" && actions.length === 0) {
            actions.push("GetWebText");
            InvoicePaymentText().then((response) => {
                setInvoiceText(response.data.textasset);
            }).catch((error) => {
                setErrors(ProcessErrors(errors));
            });
        }
    })


    return (<div className='item-container'>
               {invoiceText && <p>{ReactHtmlParser(invoiceText)}</p>}
               <div className="border-seperator"></div>
           </div>);
})