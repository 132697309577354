import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { format } from 'react-string-format';
import '../../../i18n'
import Checkbox from '../../UI/Checkbox/Checkbox';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import ReactHtmlParser from 'html-react-parser';
export function AutorenewalTerms(props, errorStates) {
    const { t, i18n } = useTranslation();
    const [isChecked, setIsChecked] = useState(false);

  

    useEffect(() => {

        props.setIsAutoRenewalChecked(isChecked);
        if (isChecked) {
            props.setErrorStates(errorStates => ({ ...errorStates, autoRenewalNotTicked: false }))
        }

    }, [isChecked])

    return (
        <div className="item-container">
            <label className="label">{t('autorenewalterms.title')}</label>
                <div className="item-wrapper">
                {props?.renewalText && <p>{ReactHtmlParser(props?.renewalText)}</p>}
                    <Checkbox label={t('autorenewalterms.consentcheckboxlabel')} checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} id="autorenewalconsent" name="autorenewalconsent" />
                    {props.errorStates.autoRenewalNotTicked ?
                        <span role="alert" id='payment-not-selected'><ErrorBox>{t('payment.autorenewalcheckboxnotticked')}</ErrorBox></span>
                        : ""}
                </div>
            </div>
    );
}

