import { Fragment, useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import "../Inputs/InputRe.css";
import "./Dropdown.scss"
import useCreateUrl from '../../../Hooks/useCreateUrl';

const Dropdown = (props) => {
    let inputWidth = () => {
        switch (props.inputSize) {
            case 'full-width':
                return "full-width"
            case 'large':
                return "large"
            case 'medium':
                return "medium"
            case 'small':
                return "small"
            default:
                return "large"
        }
    }


    const handleChange = (e) => {
        props.setValue(e.target.value); //for storing the selection

    }


    return <>

        <div class="dropdown-wrapper">

            <div className={`${props?.inputSize}`}>


                <div class="label-wrapper">
                    <div class="label-text">
                        <label className="label" htmlFor={props.id}> {props.label} </label>
                        {props?.description ? <p className="label-description">{props?.description}</p> : ""}

                    </div>

                </div>

                <select onChange={handleChange}>
                    <option>{props.selectItemInstructions} </option>
                    {props.options.map((option) => (
                        <option value={option.code}>{option.name}</option>
                    ))}

                </select>

            </div>
        </div>

    </>
}
export default Dropdown;