import { Fragment, useState, forwardRef } from "react";
import { Link } from "react-router-dom";
//import { shouldProcessLinkClick } from "react-router-dom/dist/dom";
const Checkbox = forwardRef((props, ref) => {

    const { inputSize, label, id, labelText} = props;


    const [showHelp, setShowHelp] = useState(false);


    //basic text input with a label along with a standard subtext with an exception of email input box

    let inputWidth = () => {
        switch (inputSize) {
            case 'full-width':
                return "full-width"
            case 'large':
                return "large"
            case 'medium':
                return "medium"
            case 'small':
                return "small"
            default:
                return "large"
        }
    }

    const helpLinkHandler = (e) => {
        e.preventDefault();
        return setShowHelp(!showHelp);
    }




    return <>
        <div className="field-container">
            <div class="field-wrapper">
                <label className="checkbox-label" htmlFor={id}> 
                    {/* if has a heading and also text label then        */}
                    <input className={inputWidth()} type="checkbox" label={labelText ? labelText : label} id={id } {...props} />
                    <span class="inputText-wrapper">{labelText ? labelText : label}</span>
                </label>
            </div>
        </div>

    </>


})
export default Checkbox;