import { useState, useEffect } from "react";
import Button from "../../UI/Buttons/Button";
import Variant from "./Variant";
import "./Variant.scss";
import ReactHtmlParser from 'html-react-parser';
import { APIGet, APIPost } from '../../API/APIRequest';
const Variants = (props) => {

    const [productVariants, setProductVariants] = useState([]);

    const loadData = () => {

        APIGet({
            "controller": "product",
            "action": "getproductvariants",
            "environment": null
        }).
            then((response) => {

                setProductVariants(response.data);
            });
    }

    const variantData = [
        {
            highlighted: false,
            badge: "New Offer",
            emphasis: false,
            title: "This offer is awesome",
            titleDescription: "The awesome description.",
            previousPrice: "7.99",
            price: "5.99",
            priceTerms: "Monthly",
            taxText: "Tax/Vat",
            priceDescription: "<p>Your subscription will start today and renew automatically on the same day each month </p>",
            offerButton: "Subscribe",
            offerDescription: '<ul><li> Get unlimited access to our website, with 100s of new articles added each month. </li><li>  Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage. </li></ul > '
        },
        {
            highlighted: true,
            badge: "New Offer",
            emphasis: false,
            title: "This offer is awesome",
            titleDescription: "The awesome description.",
            previousPrice: "7.99",
            price: "5.99",
            priceTerms: "Monthly",
            taxText: "Tax/Vat",
            priceDescription: "<p>Your subscription will start today and renew automatically on the same day each month </p>",
            offerButton: "Subscribe",
            offerDescription: '<ul><li> Get unlimited access to our website, with 100s of new articles added each month. </li><li>  Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage. </li></ul > '
        }, {
            highlighted: false,
            badge: "New Offer",
            emphasis: false,
            title: "This offer is awesome",
            titleDescription: "The awesome description.",
            previousPrice: "7.99",
            price: "5.99",
            priceTerms: "Monthly",
            taxText: "Tax/Vat",
            priceDescription: "<p>Your subscription will start today and renew automatically on the same day each month </p>",
            offerButton: "Subscribe",
            offerDescription: '<ul><li> Get unlimited access to our website, with 100s of new articles added each month. </li><li>  Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage. </li></ul > '
        }, {
            highlighted: false,
            badge: "New Offer",
            emphasis: false,
            title: "This offer is awesome",
            titleDescription: "The awesome description.",
            previousPrice: "7.99",
            price: "5.99",
            priceTerms: "Monthly",
            taxText: "Tax/Vat",
            priceDescription: "<p>Your subscription will start today and renew automatically on the same day each month </p>",
            offerButton: "Subscribe",
            offerDescription: '<ul><li> Get unlimited access to our website, with 100s of new articles added each month. </li><li>  Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage. </li></ul > '
        }, {
            highlighted: false,
            badge: "New Offer",
            emphasis: false,
            title: "This offer is awesome",
            titleDescription: "The awesome description.",
            previousPrice: "7.99",
            price: "5.99",
            priceTerms: "Monthly",
            taxText: "Tax/Vat",
            priceDescription: "<p>Your subscription will start today and renew automatically on the same day each month </p>",
            offerButton: "Subscribe",
            offerDescription: '<ul><li> Get unlimited access to our website, with 100s of new articles added each month. </li><li>  Complete access to our online archive of over 15,000 articles spanning more than a decade of coverage. </li></ul > '
        }
    ]
   
    useEffect(() => {
    
        loadData();
    }, [])

    return (
        <>
        <div className="variants-wrapper">
                <div className="variants-content">
                    {variantData.map(item => 
                        <Variant
                            highlighted={item.highlighted}
                            badge={item.badge}
                            emphasis={item.emphasis}
                            title={item.title}
                            titleDescription={item.titleDescription}
                            previousPrice={item.previousPrice}
                            price={item.price}
                            priceTerm={item.priceTerms}
                            taxText={item.taxText}
                            priceDescription={item.priceDescription}
                            buttonText={item.offerButton}
                            offerDescription={item.offerDescription}
                        />

                    )}

                </div>
        </div>
 
        </>
    )

}

export default Variants;