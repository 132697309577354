import { useTranslation} from 'react-i18next';
import React, { forwardRef, useContext, useState, useImperativeHandle, useRef, useEffect } from 'react';
import { client, dataCollector } from "braintree-web"
import dropin from "braintree-web-drop-in"
import '../../../i18n'
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import { APIPost } from '../../API/APIRequest'
import { gtmEventTriggers} from '../../Utilities/GtmFunctions';
import { appendClientQueryParams} from '../../Utilities/Functions';


 export const GetPaypalClientToken = (fid) => {
        return new Promise((resolve, reject) => {
               
            APIPost({
                "controller": "Paypal",
                "action": "GetClientToken",
                "environment": null,
                "identifier": fid
            }).then((response) => {
              
                resolve(response.data.clienttoken);
            }).catch((error) => {
                reject(error);
            });           
        });
    }

// dummy function to keep process happy
export const UpdatePaypalPaymentRequest = (props) => {
    return new Promise((resolve, reject) => {
       resolve(true);
    });
}
 


export const PaypalForm = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [ paypalClientToken, setPaypalClientToken ] = useState(null);
    const [ dropinInstance, setDropinInstance ] = useState(null);
    const [dropinInitialized, setDropinInitialized] = useState(false);
    
    const fid = props.basket.id;

   
    const observer = new MutationObserver((mutations, observer) => {
        mutations.forEach((mutation) => {
            const { target } = mutation;
         
            if (mutation.attributeName === 'class') {
                if (mutation.target.classList.contains('braintree-show-methods')) {
                    //GetPaymentMethod();
                }
            }
        });
    });

    const AttachObserver = () => {
        var dropinContainer = document.getElementById('dropin-container');

        observer.observe(dropinContainer, {
            subtree: true,
            attributes: true
        });
        //var dropinInstance = null;
        //var clientInstance = null;
        //var deviceData = null;

        //GetDeviceData();      
    }
    
    const GetDeviceData = () => {
        return new Promise((resolve, reject) => {
            client.create({
                    authorization: paypalClientToken
                }, function(errClientCreate, clientInstance) {
                // Creation of any other components...
                if (errClientCreate) {
                  
                    reject(errClientCreate);
                }
                dataCollector.create({
                    client: clientInstance
                }, function (errCollectorCreate, dataCollectorInstance) {
                    if (errCollectorCreate) {
                        // Handle error in creation of data collector
                      
                        reject(errCollectorCreate);
                    }
                 
                    // At this point, you should access the dataCollectorInstance.deviceData value and provide it
                    // to your server, e.g. by injecting it into your form as a hidden input.
                    resolve(dataCollectorInstance.deviceData);
                });
            });
        });        
    }


    const GetPaymentMethod = (deviceData) => {
        return new Promise((resolve, reject) => {
       
        
            if (dropinInstance) {
                dropinInstance.requestPaymentMethod((error, payload) => {
                    // Step four: when the user is ready to complete their
                    //   transaction, use the dropinInstance to get a payment
                    //   method nonce for the user's selected payment method, then add
                    //   it a the hidden field before submitting the complete form to
                    //   a server-side integration
                 
                APIPost({
                        "controller": "Paypal",
                        "action": "ProcessPayment",
                        "environment": null,                      
                        "data": {
                            fid: fid,
                            deviceData: deviceData,
                            nonce: payload.nonce,
                            email: payload.details.email
                        }
                    }).then((response) => {
                        resolve(response);
                    }).catch((error) => {
                        reject(error);
                    });

                });
            }
        });
    }

    const InitializeDropIn = (paypalClientToken) => {
        return new Promise((resolve, reject) => {
            dropin.create({
                authorization: paypalClientToken,
                container: '#dropin-container',
                card: false,
                paypal: {
                    flow: 'vault',
                    landingPageType: 'login',
                    buttonStyle: {
                      color: 'black',
                      shape: 'rect',
                      size: 'medium',
                      label: 'paypal',
                      tagline: 'false'
                  }
                }
            }, (createError, instance) => {
                if (createError) {
                 
                    reject(createError);
                }
                else {
                    instance.on('paymentMethodRequestable', onAuthenticated);
                    resolve(instance);
                }
            });
        });
       
    }

    const onAuthenticated = (event) => {
        props.onAuthentication(event.paymentMethodIsSelected);
    }

    

    useImperativeHandle(ref, () => ({
        confirmPayment(event) {
            GetDeviceData().then((deviceData)=>{
                GetPaymentMethod(deviceData).then((response)=>{
                  
                    gtmEventTriggers("add_payment_info")
                    
                    let url= window.location.protocol + "//" + window.location.host + "/thankyou/" + response.data.OrderId;
                    let updatedUrl= appendClientQueryParams(url);
                    window.location.href = updatedUrl;
                
                }).catch((paymentMethodError) => {
                    setErrors(paymentMethodError);
                })
            }).catch((deviceError) => {
                setErrors(deviceError);
            });
        },
        errors
    }));

    useEffect(() => {
       
        if (!paypalClientToken) {
            GetPaypalClientToken(props.basket.id).then((response) => {
              
                setPaypalClientToken(response);
            }).catch((error) => {

            });
        }
        
       if (paypalClientToken) {
            if (dropinInstance) {
                dropinInstance
                    .teardown()
                    .then(() => {
                        InitializeDropIn(paypalClientToken).then((response) => {
                         
                            setDropinInstance(response);
                            setDropinInitialized(true);
                        }).catch((error) => {

                        });
                    });
            } else {
                InitializeDropIn(paypalClientToken).then((response) => {
                
                    setDropinInstance(response);
                    setDropinInitialized(true);
                }).catch((error) => {

                });
            }
        }
    
    }, [paypalClientToken, dropinInitialized])

   
    return (
        <> 
        <div className="paypal item-container">
            <div className="item-wrapper one-row"> 
                    <p><small>{t('paypal.instructionslabel')}</small></p>
            </div> 
         </div>

        <div className="item-container">
            <div id="dropin-container"></div>
        </div>
        </>
    );
})