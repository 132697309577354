import { useParams, Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import cssHeader from './Header.css';
import HeaderBranding from "./HeaderBranding";


const AbacusHeader = props => { 
   
    return (
        <Fragment>
            <header className="header">
                <div className="sleeve">
                    <HeaderBranding brandingHeader={props.brandHeader}> </HeaderBranding> 
                </div>
            </header>
        </Fragment>
    )
}


export default AbacusHeader;