import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
import { LogWebActivity } from '../Webactivity/LogWebActivity';
import { Acknowledgement } from './OrderComplete/Acknowledgement'
import { OrderSummary } from './OrderComplete/OrderSummary'


export function OrderComplete(props) {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <h2>{t('ordercomplete.title')}</h2>
            <Acknowledgement />
            <OrderSummary />
            <LogWebActivity />
        </div>
    )
}