import React, { Component, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetADToken, RemoveADToken, RemoveAuth0Token } from '../../Utilities/TokenManager';
import { GetCookieName, DeleteCookie } from '../../Utilities/ADFECookie';
import { GetConfiguration } from '../../Configuration/ConfigurationManager';
import { useAuth } from '../AuthenticationProvider';
import {User} from '../../Checkout/User/User';
import {GuestUser} from '../../Checkout/User/GuestUser';
export function LoginInfo(props) {

    const { t, i18n } = useTranslation();
    const auth = useAuth();
    
    //const [userToken, setUserToken] = useState(null);

    const logoutWithRedirect = (event) => {
        event.preventDefault();
        //auth.Logout(window.location.origin);
        window.location.href = "/logout";
    }
    
    var userToken = auth.userData;
    let name = userToken?.username;
    return (<User userEmail={name}/>);
    
    // else { return (<><GuestUser/></>)
    // }
     
}
