import { forwardRef, Fragment, useState } from "react";
import './Input.css';
import './ToggleInput.css';
const InputNew =(props) => {

    const { description, altText, showHelp, typeInThree, specs, value } = props;
    const [filled, setFilled]= useState(false) 
    let inputType = () => {
        switch (props.inputType) {
            case 'text':
                return "text"
            case 'email':
                return "email"   //added by megha
            case 'checkbox':
                return "checkbox"
            case 'radio':
                return "radio"
            case 'submit':
                return 'submit'
            default:
                return "text"
        }
    }

    let inputSize = () => {
        switch (props.inputSize) {
            case 'full-width':
                return "full-width"
            case 'medium':
                return "medium"
            case 'small':
                return "small"
            default:
                return "full-width"
        }
    }

   
    //switch case for returning UI on the basis of inputType
   const basicInputUI=()=>{
    switch (props.inputType) {
        case 'text':
            return get_Text_Input()
        case 'password':
                return get_Text_Input()    
        case 'radio':
            return get_Radio_Input()
        case 'checkbox':
            return get_Radio_Input()
        default:
            return get_Text_Input()
    }
   }

   //for checkboxes and radios
    const get_Radio_Input = () => {
        return <>
            {specs == "toggle_switch" ?
                <input className="switch-input" type={props.inputType}  {...props} />
                :
                <input type={props.inputType}   {...props} />
            }
               </>
    }

    //for text inputs, emails.
    const get_Text_Input = () => {
        return <>
  
    
            <input valid={false} className={`${props.inputSize} fs-16 pd-12 ${props.invalidClass ? props.invalidClass : (value ?.length>0) ? "filled" : ""} `} type={props.inputType||props.type}  {...props} />
              </>
    }

    return <Fragment>
          {basicInputUI()}
          </Fragment>
}



export default InputNew