
import { emailRegexUtils, nameRegexUtils, addressRegexUtils } from "./EmailRegex";


export function checkIsBlank(value) {

    if (value && value <1) {
        return true;
    }
    else if(!value){
        return true;
    }
    else {
        return false;
    }

}

export function checkIsInvalid(value, type) {

    const emailRegex = emailRegexUtils;
    if (type === "userEmail") {
        let emailValue = value || "";
        let temp = emailValue?.split("@");
        let localPart = temp ? temp[0] : "";
        if ((emailValue?.match(emailRegex) || []).length <= 0 || localPart.length > 64) {
            return true;
        }
        else {
            return false
        }

    }
    else if (type === "firstName" || type === "lastName") {
        const nameRegex = nameRegexUtils;
        return !nameRegex.test(value);
    }
    else if (type === "address") {
        const addressRegex = addressRegexUtils;
        return !addressRegex.test(value);
    }
}