import React, { useRef, useEffect} from 'react';
import ReactDOM from 'react-dom'; //get the portal from
import './modal.scss'

const Backdrop = (props) => {
    return <div className="backdrop" />
}


const ModalOverlay = props => {
    const modalContentRef = useRef(null);

    useEffect(() => {
        if (props.isOpen) {
            modalContentRef.current.focus();
        }
    }, [props.isOpen]);

        return( 
        <div className={`container modal ${props.isOpen ? 'open' : ''}`}>
            <button className="close-button" onClick={props.onClose}>close</button>
                <div className="modal-content" tabIndex="-1" ref={modalContentRef}>
                {props.children}
            </div>
            </div>
        )
}
const Modal = (props) => {
    return(
        <>
            {ReactDOM.createPortal(
                <Backdrop />,
                document.getElementById('backdrop-root')
            )};
            {ReactDOM.createPortal(
                <ModalOverlay {...props} />,
                document.getElementById('overlay-root')
            )};
    </>
    )};

export default Modal;