import OptionCards from "./OptionCards/OptionCards";
import { Promo } from "./Promo/Promo";
import Variant from "./Variants/Variant";
import Variants from "./Variants/Variants";

export function ProductOffer(){

    return(
        <div className="container-wrapper one-column">
            <div className="container">
            <Variants />
             <Promo/>
             <OptionCards/>

            </div>
        </div>
    )

}