import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    
  }

  render() {
    if (this.state.error) {
      // Render fallback UI when an error occurs
      return (
        <div style={{width:"400px", wordWrap: "wrap"}}>
          <h2>Something went wrong</h2>
          <p>{this.state.error.toString()}</p>
          <div style={{width:"400px", wordWrap: "wrap"}}>
            <h3>Error details:</h3>
            <pre style={{width:"400px", wordWrap: "wrap", display:"hidden"}}>{JSON.stringify(this.state.errorInfo, null, 2)}</pre>
          </div>
        </div>
      );
    }

   
    return (
      <div>
      {this.props.children}
      </div>
    );
  }
}

export default ErrorBoundary;
