import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n'
import { useAuth } from './AuthenticationProvider'
import { useParams, Link } from 'react-router-dom'
import { GetConfiguration } from '../Configuration/ConfigurationManager'
import { GetADToken } from '../Utilities/TokenManager'
import { IsSafeUrl, RedirectWarning  } from '../Utilities/SafeUrl'
import { ErrorBox } from '../Utilities/ErrorBox'
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';

export function Logout(props) {
    const auth = useAuth();
    const [errors, setErrors] = useState([]);
    const [isNotTrusted, setIsNotTrusted] = useState('');
    const { environment } = useParams();
    const [currentOperation, setCurrentOperation] = useState([]);

    var params = (new URL(document.location)).searchParams;
    var returnUrl = params.get("ReturnUrl");
    var refToken = params.get("refToken");
    if (!returnUrl) {
        returnUrl = window.location.origin;
    }
    var isCallback = params.get("IsCallback");
    
    if (!isCallback && currentOperation.length == 0) {
        currentOperation.push("logout");
        auth.Logout(returnUrl, refToken);
    }
    else {
        if (auth.userData) {
            auth.ClearUserData();
        }
    }
   
    var canRedirect = !auth.userData;
    if (canRedirect) {
        IsSafeUrl(returnUrl, environment).then((result) => {
            if (result) {
                window.location.href = returnUrl;
            }
            else {
                if (!isNotTrusted) {
                    setIsNotTrusted(returnUrl);
                }
            }
        }).catch((error) => {
            errors.push(error);
        });
    }
    return (<div className="center-column">
        <GenericErrorBox errors={errors} />
        <RedirectWarning isNotTrusted={isNotTrusted} returnUrl={returnUrl} />
    </div>);
}