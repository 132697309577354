import React, { useState, Fragment } from "react";
import ReactHtmlParser from 'html-react-parser';

const FooterBranding = props => {
    const footerData = props?.brandingFooter || '';
    return (
        <Fragment>
            {ReactHtmlParser(footerData)} 
        </Fragment>
       )
    }
export default FooterBranding;

