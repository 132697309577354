import { useTranslation } from 'react-i18next';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import '../../../i18n';
import { useState } from "react";
import { format } from 'react-string-format';
import "./User.scss"
import { Link } from 'react-router-dom';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import ErrorBox from '../../UI/ErrorBox/ErrorBox';
import setStateAsync from '../../Utilities/SetStateAsync';
import { checkIsBlank, checkIsInvalid } from '../../Utilities/FieldValidations';
import Button from '../../UI/Buttons/Button';
import { LoginRedirect } from '../../Utilities/LoginRedirect';
import { Logout } from '../../Authentication/Logout';


export function User({ userEmail, loggedIn }) {
  const createUrl = useCreateUrl()
  const { t, i18n } = useTranslation();
  let text = format(t('checkout.usersignedintext'),)
 
  return (<div className="detail-wrapper">
        <p>{text} <strong>{userEmail}</strong></p>
        {/* web activity: need to fire checkout-started web act log again if the user logs out and logs in*/}
        {/* <button  onClick={(e)=>{LoginRedirect(); sessionStorage.removeItem("CheckoutStarted")}}>Change</button> */}
        </div>
    
  );
}