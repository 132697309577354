import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APIGet } from '../API/APIRequest'
import { GetCookieValue } from '../Utilities/ADFECookie'
import { GetADToken, RemoveADToken } from '../Utilities/TokenManager';
import parse from 'html-react-parser';

export function PageNotFound(props) {
    const { t, i18n } = useTranslation();

    const [message, setMessage] = useState({});

    useEffect(() => {
        APIGet({
            "controller": "textasset",
            "action": "getsitetextassetbyname",
            "data": { name: "pagenotfound" },
            "environment": null     
        }).then((response) => {
           
            setMessage({ title: "", message:  parse(response.data.textasset) });
        }).catch((error) => {
            setMessage({ title: t('pagenotfound.title') , message: t('pagenotfound.message') });   
        });
        
    }, [])

    return (
        <div>
           <div className="container-wrapper">
             <div className="container">
                  <div className="item-container">
                {message?.title ? <h1>{message.title}</h1> : ""}
                {message?.message}        
                </div>
            </div>   
            </div>
        </div>
    )
}





